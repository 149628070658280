import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { RootState } from "src/state";
import Main from "src/Widgets/Main";
import totalsupply from "src/assets/icons/totalsupply.png";
import burn from "src/assets/icons/burn.png";
import circulatingsupply from "src/assets/icons/circulatingsupply.png";
import logo2 from "src/assets/logos/logo2.png";
import marketcap from "src/assets/icons/marketcap.png";
import balance from "src/assets/icons/balance.png";
import Stats from "./components/Stats";
import PriceStatsCard from "./components/PriceStatsCard";
import { useTokenStats } from "src/hooks/useTokenStats";
import viper from "src/assets/images/viper.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    display: "grid",
    justifyContent: "space-evenly",
    alignContent: "center",
    rowGap: 50,
    marginTop: 30,
    gridTemplateColumns: "repeat(3, 20%)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
      justifyContent: "center",
    },
  },
  priceStats: {
    background: theme.palette.text.primary,
    color: "white",
  },
  balance: {
    background: "transparent",
    border: "2px solid white",
  },
  marketcap: {
    background: "transparent",
    border: "2px solid white",
  },
  viper: {
    width: 250,
    position: "absolute",
    left: "80%",
    top: "30%",
    // opacity: 0.8,
    // mixBlendMode: "luminosity",
    [theme.breakpoints.down("md")]: {
      zIndex: -1,
      top: "60%",
      left: "30%",
      opacity: 0.4,
      mixBlendMode: "luminosity",
    },
  },
}));

const Dashboard = () => {
  const open = useSelector((state: RootState) => state.drawer.open);
  const classes = useStyles();
  const tokenStats = useTokenStats();

  return (
    <Main open={open} className={classes.root}>
      <Stats title={"Total Supply"} value={tokenStats.totalSupply} imgUrl={totalsupply} />
      <Stats title={"Circulating Supply"} value={tokenStats.circulatingSupply} imgUrl={circulatingsupply} />
      <Stats title={"Burn"} value={`${tokenStats.burnBalance}`} imgUrl={burn} />
      <PriceStatsCard
        imgUrl={logo2}
        title={"ANTZ Price"}
        value={"$ " + tokenStats.price}
        className={classes.priceStats}
      />
      <PriceStatsCard
        imgUrl={balance}
        title={"Balance"}
        value={`${tokenStats.displayBalance} ANTZ`}
        usdValue={tokenStats.balanceUsd}
        className={classes.balance}
      />
      <PriceStatsCard
        imgUrl={marketcap}
        title={"Market Cap"}
        className={classes.marketcap}
        usdValue={tokenStats.marketCapUsd}
      />
      <img src={viper} className={classes.viper} />
    </Main>
  );
};

export default Dashboard;
