import "./App.css";
import React from "react";
import Routes from "./Routes";
import { UtilsProvider } from "@react-dapp/utils";
import { useEagerConnect, useWallet } from "@react-dapp/wallet";
import { getDefaultValues } from "./utils";
import { ModalObject, ModalProvider } from "./context/ModalContext";
import { setOpen } from "src/state/drawer/drawerReducer";
import { NftVillageSdkProvider } from "@nftvillage/marketplace-sdk";
import useNotify from "./hooks/useNotify";

import Hello from "./modals/Hello/Hello";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./state";
import { useLoadAccount } from "./hooks/useLoadAccount";

const allModals: ModalObject[] = [
  {
    name: "Hello",
    component: Hello,
  },
];

function App() {
  const { library } = useWallet();

  const { dismissNotify, dismissNotifyAll, notifyError, notifyLoading, notifySuccess } = useNotify();
  const open = useSelector((state: RootState) => state.drawer.open);
  const dispatch = useDispatch();

  useEagerConnect(true);
  useLoadAccount();

  function handleDrawerClose() {
    dispatch(setOpen(false));
  }

  interface SomeType {
    foo: string;
    bar: number;
    baz: Date;
  }

  return (
    <UtilsProvider
      config={{
        provider: library,
        rpcUrl: "https://bsc-dataseed.binance.org/",
        wrappedNative: {
          address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
          symbol: "WBNB",
          name: "WBNB",
          decimals: 18,
        },
        usd: {
          address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
          name: "BUSD",
          symbol: "BUSD",
          decimals: 18,
        },
        nativeUsdLp: {
          name: "LP",
          address: "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
          symbol: "LP",
          decimals: 18,
        },
      }}
    >
      <NftVillageSdkProvider
        config={{
          notifySuccess,
          notifyError,
          notifyLoading,
          dismissNotification: dismissNotify,
          dissmissAllNotifications: dismissNotifyAll,
          notifySystem: (title, message, type) => {
            console.log("Title:", title, "Message:", message, "Type:", type);
          },
          notifyWarning: (title, message) => {},
          notifyWarningWithInput: (title, message) => {
            return false;
          },
        }}
      >
        <ModalProvider allModals={allModals}>
          <div className="App">
            <Routes />
          </div>
        </ModalProvider>
      </NftVillageSdkProvider>
    </UtilsProvider>
  );
}

export default App;
