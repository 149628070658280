import { Tooltip, Typography, TypographyProps } from "@mui/material";
import React from "react";

interface Props extends TypographyProps {
  address?: string;
}

const AddressTypography: React.FC<Props> = ({ address, ...restProps }) => {
  const [copying, setCopying] = React.useState(false);

  const handleCopy = () => {
    setCopying(true);
    navigator.clipboard.writeText(address!);
    setTimeout(() => {
      setCopying(false);
    }, 500);
  };

  return (
    <Tooltip placement="top" arrow title={address || ""} onClick={handleCopy}>
      <Typography
        {...restProps}
        style={{ ...restProps.style, width: "max-content", cursor: copying ? "wait" : "pointer" }}
      >{`${address?.substring(0, 4)}...${address?.substring(address?.length - 4, address?.length)}`}</Typography>
    </Tooltip>
  );
};

export default AddressTypography;
