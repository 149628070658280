import { useWallet } from "@react-dapp/wallet";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchBalance } from "src/state/user/userReducer";

export const useLoadAccount = () => {
  const dispatch = useDispatch();
  const { account } = useWallet();

  useEffect(() => {
    if (account) dispatch(fetchBalance());
  }, [account]);
};
