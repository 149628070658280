import { Box, Button, CircularProgress, IconButton, Modal, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEthers } from "@react-dapp/utils";
import { useWallet } from "@react-dapp/wallet";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IModalData } from "src/types/types";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: "2px solid #fff",
    boxSizing: "content-box",
    width: 240,
    [theme.breakpoints.down("sm")]: {},
  },
  imgContainer: {
    cursor: "pointer",
    width: "100%",
    "& > img": {
      display: "block",
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      objectFit: "cover",
      height: "230px",
    },
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
  },
  name: {
    color: theme.palette.text.primary,
  },
  price: {},
  modalBox: {
    borderRadius: 10,
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#181728",
    border: `2px solid #fff`,
    [theme.breakpoints.down("md")]: {
      width: "90vw",
    },
  },
}));

interface INFTCard {
  image: string;
  name: string;
  tokenId: number | string;
  amount: number | string;
  transferAmount: (data: IModalData) => void;
  loading: boolean;
}

const NFTCard: React.FC<INFTCard> = ({ image, name, tokenId, amount, transferAmount, loading }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { account } = useEthers();
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState<IModalData>({
    from: account as string,
    tokenId: tokenId,
    userAddress: "",
    amount: amount as string,
  });

  const handleOpen = () => {
    console.log("Modal Data", modalData);

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setModalData((prevValue) => ({
      ...prevValue,
      userAddress: "",
    }));
  };

  const handleModalData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setModalData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDataSubmit = async (data: IModalData) => {
    await transferAmount(data);
    handleClose();
  };

  const handleMax = () => {
    setModalData((prevValue) => ({
      ...prevValue,
      amount: amount.toString(),
    }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.imgContainer} onClick={() => navigate(`/account/${tokenId}`)}>
        <img src={image} alt={name} />
      </div>
      <div className={classes.textContainer}>
        <div style={{ padding: 0, margin: 0 }}>
          <Typography variant="h5" className={classes.name}>
            {name}
          </Typography>
          <Typography className={classes.price}>ID # {tokenId}</Typography>
        </div>
        <IconButton
          onClick={handleOpen}
          sx={{
            border: (theme) => `1px solid ${theme.palette.text.primary}`,
            borderRadius: "10%",
            alignSelf: "flex-end",
            width: 30,
            height: 30,
          }}
        >
          <CallMadeOutlinedIcon color="primary" />
        </IconButton>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalBox}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            Transfer Amount
          </Typography>
          <TextField
            sx={{
              width: "100%",
              mt: 2,
              mb: 2,
            }}
            name="userAddress"
            value={modalData.userAddress}
            onChange={handleModalData}
            placeholder={"User Address"}
            InputProps={{
              sx: {
                width: "100%",
                border: (theme) => `2px solid ${theme.palette.text.primary}`,
              },
            }}
            inputProps={{
              sx: {
                border: "none",
              },
            }}
          />
          <TextField
            sx={{
              width: "100%",
              mb: 2,
            }}
            name="amount"
            value={modalData.amount}
            onChange={handleModalData}
            placeholder={"Amount"}
            InputProps={{
              endAdornment: <Button onClick={handleMax}>MAX</Button>,
              sx: {
                width: "100%",
                border: (theme) => `2px solid ${theme.palette.text.primary}`,
              },
            }}
          />
          <Button disabled={loading} variant="contained" onClick={() => handleDataSubmit(modalData)}>
            Transfer {loading ? <CircularProgress sx={{ color: "inherit", ml: 1 }} size={20} /> : null}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default NFTCard;
