export interface MarketplaceSdkConfig {
  supportedERC20Tokens?: [];
  /**
   * Takes title and message and type of alert, only used for developer mode
   */
  notifySystem: (title: string, message: string, type: NotificationType) => void;
  /**
   *  Shows success alert to user
   */
  notifySuccess: (title: string, message: string) => void;
  /**
   *  Shows success error to user
   */
  notifyError: (title: string, message: string) => void;
  /**
   *  Shows loading alert and returns the id of alert which will later be used to close the alert
   */
  notifyLoading: (title: string, message: string) => string;
  /**
   * Closes the alert with the given id
   */
  dismissNotification: (id: string) => void;
  /**
   * Closes all alerts
   */
  dissmissAllNotifications: () => void;
  /**
   * Shows Warning notification to user, with Accept or Decline optional
   */
  notifyWarningWithInput: (title: string, message: string) => boolean;
  /**
   * Shows Warning notification to user.
   */
  notifyWarning: (title: string, message: string) => void;
}

export enum NotificationType {
  SUCCESS,
  WARNING,
  ERROR,
}

export type TASKS_TYPE = {
  [P in keyof typeof CURRENT_TASK]: { start: () => void; stop: () => void; isRunning: () => boolean };
};

export enum CURRENT_TASK {
  searchMarketplace,
  createCollection,
  fetchCollectionByShortUrl,
  fetchCollectionByAddress,
  getAllCollectionUserAddress,
  checkShortUrlAvailability,
  deployErc721Contract,
  deployErc1155Contract,
  fetchingInventory,
  mintErc721Token,
  createSellOrder,
  getAllCollections,
  cancelOrder,
  signOrder,
  buyFixPriceOrderErc721,
  fetchErc721Order,
  fetchErc1155Order,
  fetchAllListedOrders,
  fetchAllOrdersOfCollection,
  fetchAllListedOrders_BY_USER_ADDRESS,
  fetchSpecificOrder,
  getOrderHistoryOfAsset,
  buyAnyOrder,
  fetchCategories,
  fetchTokensOfUserAddress,
  approveTokenForOrder,
}
export interface OrderData {
  asset?: string;
  assetId: number;
  assetAmount?: number;
  assetType?: AssetType;
  exchange?: string;
  relayer?: string;
  relayerFee?: number;
  maker: string;
  taker?: string;
  protocolFee?: number;
  side: OrderSide;
  saleKind: SaleKind;
  paymentToken?: string;
  basePrice: string;
  reservePrice?: string;
  listingTime?: number;
  expirationTime?: number;
  salt?: string;
  _id?: string;
}
export interface Order {
  order: OrderData;
  orderHash?: string;
  referrer?: string;
  status?: StatusType;
  expirationTime?: number;
  signature?: string;
  metadata: {
    attributes: Attributes[] | [];
    image?: string;
    background_color?: string;
    external_url?: string;
    animation_url?: string;
    youtube_url?: string;
    category?: string;
    name?: string;
    description?: string;
    address: string;
    tokenId: number;
    collectionName: string;
    makerAddress: string;
    price: number;
    rarity?: string | number;
    [key: string]: any;
  };
  bids?: Bids[] | [];
  createdAt?: string;
}

export interface OrderBid {
  asset: string;
  assetId: number;
  orderHash?: string;
  signature?: string;
}
export interface OrderAsset {
  asset: string;
  assetId: number;
}

export interface SpecificOrderData {
  [key: string]: number[];
}

export interface Collection {
  userAddress: string;
  isVerified?: boolean;
  address: string;
  name?: string;
  description?: string;
  symbol?: string;
  shortUrl?: string;
  coverImage?: string;
  logoImage?: string;
  royalty?: number;
  collectionStandard: AssetType;
}

export interface CollectionData {
  name?: string;
  description?: string;
  symbol?: string;
  tokenUri?: string;
  shortUrl?: string;
  coverImage?: string;
  mainImage?: string;
  royalty?: number;
  isVerified?: boolean;
}

export interface Token {
  address: string;
  tokenId?: number;
  tokenUri: string;
  metadata: MetaData;
  fees: Fee[] | [];
  minter: string;
  owner: string;
}

export interface MetaData {
  attributes: Attributes[] | [];
  image?: File | string;
  background_color?: string;
  external_url?: string;
  animation_url?: File | string;
  youtube_url?: string;
  category?: string;
  name?: string;
  description?: string;
}

type Bids = {
  order: OrderData;
  orderHash: string;
  signature: string;
};
type Attributes = {
  trait_type: string;
  value: string | number;
  display_type?: string;
};
export type Fee = {
  recipient: string;
  value: number;
};

export interface AllOrders {
  totalOrders?: number;
  totalPages?: number;
  error?: any;
  results: Order[];
}

export enum TokenStandard {
  ERC20,
  ERC721,
  ERC1155,
}

export enum AssetType {
  ERC721,
  ERC1155,
}
export enum OrderSide {
  BUY,
  SELL,
}

export enum SaleKind {
  BUYNOW = 0,
  ENGLISHAUCTION = 1,
  DUTCHAUCTION = 2,
}
export enum StatusType {
  LISTED,
  PENDING,
  COMPLETED,
  CANCELLED,
}
export type SortBy = "LATEST" | "OLDEST" | "PRICE_LOW_TO_HIGH" | "PRICE_HIGH_TO_LOW";

export interface FilterMarketPlace {
  address?: string;
  makerAddress?: string;
  name?: string;
  collectionName?: string;
  category?: string;
  minPrice?: string;
  maxPrice?: string;
  sortBy?: SortBy;
  rarity?: string | number;
  type?: AssetType;
  page?: number;
}

export interface SearchMarketPlace {
  names: string[];
  collectionNames: string[];
}

export interface Delete {
  message: string;
  status: boolean;
}

export interface ApiResponse<T> {
  status: boolean;
  message: string;
  data?: T;
  error?: any;
}

export interface HistoryOfOrder {
  maker: string;
  taker: string;
  price: number;
  purchaseDate: string;
  makerName: string;
  takerName: string;
  makerProfilePic: string;
  takerProfilePic: string;
  assetAmount?: number;
}

export interface CreateItemState extends Token {
  fixedPrice?: number;
  putOnSale?: boolean;
  hasExpiryDate?: boolean;
  expiryDate?: any;
}
