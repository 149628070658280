import { useEffect, useState } from "react";
import { toLowerUnit, toUpperUnit } from '../utils'
import BigNumber from "bignumber.js";

export const useInputValue = (maxValue: string, decimals: number = 18, fixedDecimals: number = 2) => {
    const [value, _setValue] = useState('');
    const [max, _setMax] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [maxDisplayValue, setMaxDisplayValue] = useState<string | null>(null);

    useEffect(() => {
        setMaxDisplayValue(toLowerUnit(maxValue, decimals).toFixed(fixedDecimals));
    }, [maxValue]);

    const setValue = (_value: any) => {
        _setValue(_value ?? 0);

        if (_value !== '' && isNaN(_value)) {
            setError("Invalid Number!");
            return;
        }
        if (!_value || _value === "" || _value === "0") {
            setError(null)
            return
        };

        if (maxDisplayValue === _value) return;
        _setMax(false);

        if (toUpperUnit(_value, decimals).gt(new BigNumber(maxValue)))
            setError("Invalid Amount!");
        else setError(null);
    };

    const getValue = () => {
        return max
            ? new BigNumber(maxValue).toFixed(0)
            : toUpperUnit(value, decimals).toFixed(0);
    };

    const selectMaxValue = () => {
        setError(null);
        _setMax(true);
        _setValue(toLowerUnit(maxValue, decimals).toFixed(fixedDecimals));
    };

    return { value, maxDisplayValue, error, setValue, getValue, selectMaxValue };
};
