import {
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/system";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DrawerHeader from "src/Widgets/DrawerHeader";
import { useSelector, useDispatch } from "react-redux";
import { setOpen } from "src/state/drawer/drawerReducer";
import { RootState } from "src/state/index";
import logo from "src/assets/logos/logo.png";
import dashboard from "src/assets/icons/dashboard.svg";
import account from "src/assets/icons/account.svg";
import nftmint from "src/assets/icons/nftmint.svg";
import gantzswap from "src/assets/icons/gantzswap.svg";
import gantzcoach from "src/assets/icons/gantzcoach.svg";
import appupdates from "src/assets/icons/appupdates.svg";
import nftmarketplace from "src/assets/icons/nftmarketplace.svg";
import leaderboard from "src/assets/icons/leaderboard.svg";

import gamingdapp from "src/assets/icons/gamingdapp.svg";
import staking from "src/assets/icons/staking.svg";
import { makeStyles } from "@mui/styles";
import theme from "src/utils/theme";
import { useLocation, useNavigate } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  iconBtn: {
    position: "relative",
    marginLeft: "auto",
  },
  selected: {
    background: theme.palette.primary.main,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const DrawerNav = () => {
  const classes = useStyles();
  const open = useSelector((state: RootState) => state.drawer.open);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const query = useMediaQuery(theme.breakpoints.down("lg"));

  const handleDrawer = React.useCallback(() => {
    if (query && open) {
      dispatch(setOpen(false));
    } else if (!query && !open) {
      dispatch(setOpen(true));
    }
  }, [dispatch, query, open]);

  const handleDrawerClose = React.useCallback(() => {
    dispatch(setOpen(false));
  }, [dispatch]);

  React.useEffect(() => {
    window.addEventListener("resize", handleDrawer);
    return () => {
      window.removeEventListener("resize", handleDrawer);
    };
  }, [handleDrawer]);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      handleDrawer();
    });

    return () => {
      window.removeEventListener("resize", () => {
        handleDrawer();
      });
    };
  }, []);

  const linkClicked = (url: string) => {
    url[0] === "/" ? navigate(url) : window.open(url, "_blank");
    if (query) {
      dispatch(setOpen(false));
    }
  };

  const drawerMenu = [
    {
      id: 1,
      text: "Dashboard",
      link: "/",
      icon: dashboard,
    },
    {
      id: 2,
      text: "Account",
      link: "/account",
      icon: account,
    },
    {
      id: 4,
      text: "Staking",
      link: "/staking",
      icon: staking,
    },
    {
      id: 5,
      text: "NFT Staking",
      link: "/nft-staking",
      icon: gantzswap,
    },
    {
      id: 3,
      text: "ANTZ Battleground",
      link: "/leaderboard",
      icon: leaderboard,
    },

    {
      id: 5,
      text: "NFT Mint",
      link: "/minting",
      icon: nftmint,
    },
    {
      id: 6,
      text: "NFT Marketplace",
      link: "/marketplace",
      icon: nftmarketplace,
    },
    {
      id: 7,
      text: "AntzCoach Launch",
      link: "/launch",
      icon: gantzcoach,
    },
    {
      id: 8,
      text: "App Updates",
      link: "/updates",
      icon: appupdates,
    },
    {
      id: 9,
      text: "Gaming Dapp",
      link: "/gamingdapp",
      icon: gamingdapp,
    },
  ];

  const activeStyle = {
    background: theme.palette.primary.main,
  };

  return (
    <Box sx={{ display: "flex", overflowX: "hidden" }}>
      <CssBaseline />
      <Drawer
        sx={{
          // background: "black",
          overflowX: "hidden",
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "black",
          },
        }}
        variant={query ? "temporary" : "persistent"}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <img src={logo} width={120} alt="" />
        </DrawerHeader>
        {/* <IconButton onClick={handleDrawerClose} sx={{ color: theme.palette.primary.main }} className={classes.iconBtn}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon sx={{ background: "white" }} />
          ) : (
            <ChevronRightIcon sx={{ background: "white" }} />
          )}
        </IconButton> */}
        <List>
          {drawerMenu.map((menu, index) => (
            <ListItem
              key={index}
              //@ts-ignore
              sx={location.pathname === menu.link ? activeStyle : {}}
              className={classes.listItem}
              onClick={() => linkClicked(menu?.link!)}
            >
              <ListItemButton>
                <ListItemIcon sx={{ color: "white" }}>
                  <img
                    src={menu.icon}
                    alt={menu.text}
                    width={20}
                    style={{ color: "white", fill: "white", margin: 0 }}
                  />
                </ListItemIcon>
                <ListItemText primary={menu.text} sx={{ color: "white" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default DrawerNav;
