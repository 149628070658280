import React from "react";
import { useERC1155Balance } from "./useBalance";
import { useFetchMetadataForTokenIdsERC1155 } from "./useMetadata";

export const useERC1155BalanceWithMetadata = (
    erc1155Address: string,
    tokenIds: number[],
    addressUri = "",
    userAddress?: string
) => {
    const [results, setResults] = React.useState<any>([]);
    const { fetchAllMetadata } = useFetchMetadataForTokenIdsERC1155(erc1155Address, addressUri);
    const [inventoryLoading, setLoading] = React.useState(false);

    const { balance, loading } = useERC1155Balance(erc1155Address || "", tokenIds, userAddress);

    React.useEffect(() => {
        if (!inventoryLoading && loading) setLoading(true);
        if (!inventoryLoading && !loading) setLoading(false);
    }, [loading]);

    React.useEffect(() => {
        const fetchMetaData = async () => {
            setLoading(true);
            let arr =
                balance
                    ?.filter((e: any) => e && e.amount && e.amount > 0)
                    ?.map((e: any) => {
                        return {
                            amount: e.amount,
                            tokenId: e.tokenId,
                        };
                    }) || [];

            let res: any = await fetchAllMetadata(arr.map((e) => e.tokenId));
            res = res.map((item: any, i: number) => ({
                ...item,
                amount: arr[i]?.amount,
            }));

            setResults(res);
            setLoading(false);
        };
        if (balance && balance?.length > 0) fetchMetaData();
    }, [balance]);

    return {
        loading: inventoryLoading,
        results,
    };
};
