import { SignatureLike, splitSignature } from "@ethersproject/bytes";
import {
  STATE,
  useEthers,
  toUpperUnit,
  useERC20Approval,
  useERC1155Approval,
  awaitTransaction,
} from "@react-dapp/utils";
import { useEffect, useState } from "react";
import {
  getOrderERC721,
  deleteOrder,
  getAllListedOrders,
  getAllListedOrdersForAddress,
  getOrderHistory,
  getOrdersByCollection,
  getSpecificOrders,
  //postBid,
  postOrder,
  getOrderERC1155,
  hitListener,
} from "../api/order";
import { PROTOCOL_FEE, RELAYER_ADDRESS, RELAYER_FEE } from "../config/config";
import { EXCHANGE_ADDRESS, ZERO_ADDRESS } from "../config/config";
import { useExchange } from "./useContract";
import {
  Order,
  OrderData,
  StatusType,
  SpecificOrderData,
  AssetType,
  SaleKind,
  OrderAsset,
  ApiResponse,
  NotificationType,
  AllOrders,
  Delete,
  OrderSide,
  HistoryOfOrder,
} from "../config/types";
import { ethers } from "ethers";
import { useNotify } from "./useNotify";
import { useConfig } from "../contexts/nftVillageSdkContext";

/**
 * Use to create Sell order, you should approve the asset for exchange
 * 
 * @param asset (Takes a collection address) as string
 * @returns this hook returns the following functions
 * @function create function,
 * 
 * @param _order
 * 
 * ```ts
 * Order {
 *  order: OrderData,
 * 
 *  metadata {
 *   attributes: Attributes[] | [],
 *   image?: string,
 *   background_color?:string,
 *   external_url?:string,
 *   animation_url?:string,
 *   youtube_url?:string,
 *   category?:string,
 *   name?:string,
 *   description?:string,
 *   address:string,
 *   tokenId:number,
 *   collectionName:string,
 *   makerAddress:string,
 *   price:number,
 *  }
 * }
 * 
 * OrderData {
 *    assetId:number,
      assetType?:AssetType,
      assetAmount?:number,
      maker:string,
      paymentToken?: string,
      expirationTime?: number,
      basePrice:number,
 * }
 *
 * ```
 * - Useage:
 * 
 * Use this function to create the sell order for both erc721 and erc1155
 * (Note: make sure to call approve function first for nfts)
 * @returns 
 * 
 * This hook returns Api response interface 
 * where T is the Order interface
 * ```ts
 * ApiResponse<T> {
 * status: boolean
 * message: string
 * data?: T
 * }
 * ```
 * @function approve function
 * 
 * - Useage:
 * 
 * Use this function when user wants to create the sell order
 * of erc1155 token.First you need to call this function to aprove the token's.
 * 
 * @property
 * 
 * isApproved(property):boolean 
 */
export const useSellOrder = (asset: string) => {
  const { ethers, account } = useEthers();
  const exchange = useExchange();
  const { signOrder } = useSignOrder();
  const { notifySystem, notifyLoading, notifySuccess, notifyError, dismissNotification } = useNotify();
  const { approve, checkManualApproval, isApproved } = useERC1155Approval(asset, EXCHANGE_ADDRESS);
  const { Task } = useConfig();

  let response: ApiResponse<Order>;

  const create = async (_order: Order) => {
    if (!ethers) {
      notifySystem("Create Sell Order", "Unable to find ethers", NotificationType.ERROR);
      return;
    }
    Task.createSellOrder.start();
    let isApproved = await checkManualApproval(_order?.order?.asset || "");
    if (!isApproved) {
      notifySystem("Create Sell Order", "Please approve your NFT first", NotificationType.ERROR);
      Task.createSellOrder.stop();
      return;
    }

    const { asset, assetId, assetType, assetAmount, maker, paymentToken, expirationTime, basePrice } = _order.order;

    if (expirationTime && expirationTime < (await ethers.getBlock(await ethers.getBlockNumber())).timestamp) {
      notifySystem("Create Sell Order", "Expiration Time is not valid !", NotificationType.ERROR);
      Task.createSellOrder.stop();
      return;
    }

    const order: OrderData = {
      asset: asset,
      assetId: assetId,
      assetAmount: assetAmount ?? 1,
      assetType: assetType ?? AssetType.ERC721,
      exchange: EXCHANGE_ADDRESS as string,
      relayer: RELAYER_ADDRESS as string,
      maker: maker,
      taker: ZERO_ADDRESS as string,
      relayerFee: Number(RELAYER_FEE),
      protocolFee: Number(PROTOCOL_FEE),
      side: OrderSide.SELL,
      saleKind: 0,
      paymentToken: paymentToken ? paymentToken : (ZERO_ADDRESS as string),
      basePrice: toUpperUnit(basePrice).toFixed(0),
      reservePrice: toUpperUnit(basePrice).toFixed(0),
      listingTime: (await ethers.getBlock(await ethers.getBlockNumber())).timestamp,
      expirationTime: expirationTime ? expirationTime : 0,
      salt: Date.now().toString(),
    };

    try {
      const dat = await signOrder(order);

      if (!exchange || !dat) {
        notifySystem("Create Sell Order", "Unable to Sign Order!", NotificationType.ERROR);
        Task.createSellOrder.stop();

        return;
      }
      const sig = splitSignature(dat._signature);
      // @ts-ignore
      const isValidParams = await exchange.validateOrderParameters(order);
      // @ts-ignore
      const isValid = await exchange.requireValidOrder(order, sig);

      if (!isValidParams && !isValid) {
        notifySystem("Create Sell Order", "Signed Order is not valid!", NotificationType.ERROR);
        Task.createSellOrder.stop();

        return;
      }
      let orderObj: Order = {
        order: order,
        signature: dat._signature,
        orderHash: dat.hash as string,
        expirationTime: order.expirationTime,
        status: StatusType.LISTED,
        metadata: {
          ..._order.metadata,
        },
      };
      try {
        response = await postOrder(orderObj);
      } catch (e) {
        console.log(e);
        notifySystem("Create Sell Order", (e as any).response.data.message, NotificationType.ERROR);
      }
    } catch (error) {
      console.log(error);
      notifySystem("Create Sell Order", "Unable to sign", NotificationType.ERROR);
    }
    Task.createSellOrder.stop();

    return response;
  };

  const createNormalOrder = async ({ metadata, assetId, price }: { metadata: any; assetId: number; price: any }) => {
    if (account) {
      let notiId = notifyLoading("Creating Order", "Please wait...");
      let ord: Order = {
        order: {
          asset,
          assetId,
          maker: account,
          side: OrderSide.SELL,
          assetType: AssetType.ERC721,
          saleKind: SaleKind.BUYNOW,
          basePrice: price.toString(),
        },
        metadata: {
          ...metadata,
          address: asset,
          price,
        },
      };
      let res = await create(ord);
      console.log("created", res);
      dismissNotification(notiId);
      notifySuccess("Order Created", "Order created successfully");
      return res;
    }
  };

  const createERC1155Order = async ({
    metadata,
    assetId,
    price,
    assetAmount,
  }: {
    metadata: any;
    assetId: number;
    price: any;
    assetAmount: number;
  }) => {
    if (account) {
      let notiId = notifyLoading("Creating Order", "Please wait...");
      let ord: Order = {
        order: {
          asset,
          assetId,
          maker: account,
          side: OrderSide.SELL,
          assetType: AssetType.ERC1155,
          saleKind: SaleKind.BUYNOW,
          basePrice: price.toString(),
          assetAmount,
        },
        metadata: {
          ...metadata,
          address: asset,
          price,
        },
      };
      if (!isApproved) {
        let res = await approveSaleAsset();
        if (!res) {
          dismissNotification(notiId);
          return;
        }
      }
      let res = await create(ord);
      console.log("created", res);
      dismissNotification(notiId);
      if (res) {
        notifySuccess("Order Created", "Order created successfully");
      } else {
        notifyError("Oops", "Something went wrong!");
      }
      return res;
    }
  };

  const approveSaleAsset = async () => {
    let notiId = notifyLoading("Approving", "Please wait...");
    try {
      Task.approveTokenForOrder.start();
      let res = await approve();
      if (res) {
        notifySuccess("Approve", "Token Approved Successfully");
        dismissNotification(notiId);
        Task.approveTokenForOrder.stop();
        return true;
      } else {
        Task.approveTokenForOrder.stop();
        dismissNotification(notiId);
        return false;
      }
    } catch (error) {
      dismissNotification(notiId);
      notifyError("Approve", "Token Approving Error!");
      Task.approveTokenForOrder.stop();
      return false;
    }
  };

  return {
    create,
    approve,
    isApproved,
    createERC1155Order,
    approveSaleAsset,
    approving: Task.approveTokenForOrder.isRunning(),
    createNormalOrder,
    pending: Task.createSellOrder.isRunning(),
  };
};

/**
 *
 * @returns Cancel function
 * @function Cancel()
 *
 * - Useage:
 *
 * This hook is used to cancel the Order
 *
 * @param Order interface
 *
 * @returns @interface  Delete
 * ```ts
 * {
 * message:string,
 * status:boolean
 * }
 * ```
 */
export const useCancelOrder = () => {
  const exchange = useExchange();

  const { notifySystem, notifySuccess, notifyLoading, notifyError, dismissNotification } = useNotify();
  let _delete: Delete;
  const { Task } = useConfig();

  const cancel = async (order: Order) => {
    const { order: _order, signature } = order;

    if (!exchange || !signature) {
      notifyError("Error", "Signed Order is not valid!");
      return;
    }
    Task.cancelOrder.start();
    let notiId = notifyLoading("Cancel Order", "Cancelling Order...");
    try {
      const sig = splitSignature(signature);
      // @ts-ignore
      await exchange.cancelOrder(_order, sig);

      let orderData: OrderAsset = {
        asset: _order.asset as string,
        assetId: _order.assetId,
      };
      try {
        _delete = await deleteOrder(orderData);
      } catch (e) {
        notifySystem("Cancel Order", (e as any).response.data.message, NotificationType.ERROR);
        notifyError("Error", "Unable to cancel order");
      }
    } catch (e) {
      console.log(e);
      notifySystem("Cancel Order", "Unable to cancel the order from exchange", NotificationType.ERROR);
      notifyError("Error", "Unable to cancel order");
    }
    Task.cancelOrder.stop();
    dismissNotification(notiId);
    notifySuccess("Cancel Order", "Order Cancelled Successfully!");

    return _delete;
  };

  return { cancel, loading: Task.cancelOrder.isRunning() };
};

export const useSignOrder = () => {
  const [signState, setSignState] = useState(STATE.IDLE);
  const [signature, setSignature] = useState<string>();
  const [orderHash, setOrderHash] = useState<Uint8Array>();
  const exchange = useExchange();
  const { signer } = useEthers();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  const signOrder = async (order: OrderData) => {
    setSignState(STATE.BUSY);
    try {
      if (!exchange) {
        setSignState(STATE.FAILED);
        notifySystem("Sign Order", "Unable to find exchange contract !", NotificationType.ERROR);
        return undefined;
      }
      Task.signOrder.start();
      console.log("getting hash",order)
      // @ts-ignore
      const orderHashData = await exchange.getHash(order);
      console.log("order hash data", orderHash);
      const hash = orderHashData[0];
      const hashWithMessage = orderHashData[1];

      const _orderHash = ethers.utils.arrayify(hash);

      const _signature = await signer?.signMessage(_orderHash);

      if (!hash || !_signature) {
        notifySystem("Sign Order", "Unable to Sign Order", NotificationType.ERROR);
        setSignState(STATE.FAILED);
        Task.signOrder.stop();
        return undefined;
      }

      setOrderHash(hashWithMessage);
      setSignature(_signature);
      setSignState(STATE.SUCCEED);
      Task.signOrder.stop();

      return { _signature, hash: hashWithMessage };
    } catch (e) {
      console.log(e);
      notifySystem("Sign Order", "Unable to Sign Order", NotificationType.ERROR);
      setSignState(STATE.FAILED);
      Task.signOrder.stop();
      return undefined;
    }
  };
  return { signOrder, signState, signature, orderHash, loading: Task.signOrder.isRunning() };
};

// export const useOrderBid = () => {

//   const { signOrder } = useSignOrder()
//   const { ethers } = useEthers()
//   const exchange = useExchange();
//   const notify = useNotify()

//   let response: ApiResponse<Order>

//   const createBidOrder = async (_order: Order) => {

//     if (!ethers) {

//       notify({ type: NotificationType.ERROR, message: "Unable to find ethers" })
//       return
//     }
//     const {
//       asset,
//       assetId,
//       maker,
//       paymentToken,
//       taker,
//       expirationTime,
//       basePrice
//     } = _order.order;

//     if (expirationTime && expirationTime < (await ethers.getBlock(await ethers.getBlockNumber())).timestamp) {

//       notify({ type: NotificationType.ERROR, message: "Expiration Time is not valid !" })

//       return
//     }

//     const order: OrderData = {
//       asset: asset,
//       assetId: assetId,
//       assetAmount: 1,
//       assetType: AssetType.ERC721,
//       exchange: EXCHANGE_ADDRESS as string,
//       relayer: RELAYER_ADDRESS as string,
//       maker: maker,
//       taker: taker,
//       relayerFee: Number(RELAYER_FEE),
//       protocolFee: Number(PROTOCOL_FEE),
//       side: OrderSide.BUY,
//       saleKind: 0,
//       paymentToken: paymentToken ? paymentToken : ZERO_ADDRESS as string,
//       basePrice: toUpperUnit(basePrice).toFixed(0),
//       reservePrice: toUpperUnit(basePrice).toFixed(0),
//       listingTime: (await ethers.getBlock(await ethers.getBlockNumber())).timestamp,
//       expirationTime: expirationTime ? expirationTime : 0,
//       salt: Date.now(),
//     };

//     let orderObj: Order

//     try {
//       const dat = await signOrder(order)

//       if (!exchange || !dat) {

//         notify({ type: NotificationType.ERROR, message: "Unable to Sign Order!" })
//         return
//       }

//       const sig = splitSignature(dat._signature)

//       const isValidParams = await exchange.validateOrderParameters(order)
//       console.log(isValidParams)
//       const isValid = await exchange.requireValidOrder(order, sig)
//       console.log(isValid)

//       if (!isValidParams && !isValid) {
//         notify({ type: NotificationType.ERROR, message: "Signed order is not Valid" })
//         return
//       }

//       orderObj = {
//         order: order,
//         signature: dat._signature,
//         orderHash: dat.hash as string,
//         expirationTime: order.expirationTime,
//         status: StatusType.LISTED,
//         metadata: {
//           ..._order.metadata
//         }
//       };
//       try {
//         response = await postBid(orderObj);
//       } catch (e) {
//         console.log(e)
//         notify({ type: NotificationType.ERROR, message: (e as any).response.data.message })

//       }
//     } catch (error) {
//       console.log(error)
//     }
//     return response
//   };
//   return { createBidOrder };
// };

/**
 *
 * @param asset:string(collection address)
 * @param assetId:number
 * @param maker:string(maker is the buyer address)
 *
 *
 * - This function returns the following..
 * @returns
 * @function buyFixOrder()
 *
 * @function approve()
 *
 * - Useage:
 *
 * Use approve function before the buyFixOrder if the payment token address is Not Equal to
 * 0x0000000000000000000000000000000000000000 address
 *
 * @property isApproved:boolean
 *
 * @function buyFixOrder()
 * @returns
 * - This function returns
 *
 * ```ts
 * {
 * tx: any;
 * receipt: any;
 * error: any;
 * status: boolean;
 * }
 *
 * ```
 */
export const useBuyFixPriceOrderErc721 = (asset: string, assetId: number, maker: string) => {
  const sellOrderAsset: OrderAsset = {
    asset: asset,
    assetId: assetId,
  };
  const { Task } = useConfig();

  const { ethers } = useEthers();
  const exchange = useExchange();
  const { order: sellOrder } = useOrderERC721(sellOrderAsset);
  const { notifyError, notifyLoading, notifySuccess, dismissNotification } = useNotify();

  const approval = useERC20Approval(sellOrder?.order.paymentToken as string, EXCHANGE_ADDRESS as string);

  const approve = async () => {
    if (!approval.isApproved) {
      await approval.approve(sellOrder?.order.paymentToken as string);
    }
  };

  const buyFixOrder = async () => {
    if (!sellOrder || !ethers) {
      notifyError("Error", "Unable to fetch sell order");
      return;
    }
    Task.buyFixPriceOrderErc721.start();

    let notiId = notifyLoading("Buying Order", "Please wait while your transaction is being processed");
    const buyOrder: OrderData = {
      ...sellOrder.order,
      side: OrderSide.BUY,
      maker: maker,
      taker: sellOrder.order.maker,
      salt: Date.now().toString(),
    };

    try {
      let res = await hitListener();

      if (!res.value) {
        dismissNotification(notiId);
        notifyError("Error", "Oops! Server Error");
        Task.buyFixPriceOrderErc721.stop();
        return;
      }

      const sellSig = splitSignature(sellOrder.signature as SignatureLike);

      if (!exchange) {
        dismissNotification(notiId);
        Task.buyFixPriceOrderErc721.stop();
        notifyError("Error", "Unable to Sign Order!");
        return;
      }
      // @ts-ignore
      const isValidParams = await exchange.validateOrderParameters(buyOrder);

      if (!isValidParams) {
        dismissNotification(notiId);
        Task.buyFixPriceOrderErc721.stop();
        notifyError("Error", "Signed order is not Valid");
        return;
      }

      const txResponse = await awaitTransaction(
        // @ts-ignore
        exchange.atomicMatch(buyOrder, sellSig, sellOrder.order, sellSig, ZERO_ADDRESS, {
          value: sellOrder.order.paymentToken === ZERO_ADDRESS ? sellOrder.order.basePrice : null,
        })
      );
      dismissNotification(notiId);
      Task.buyFixPriceOrderErc721.stop();
      if (txResponse.status) {
        notifySuccess("Success", "Order Bought Successfully");
      }
      return txResponse;
    } catch (error) {
      dismissNotification(notiId);
      Task.buyFixPriceOrderErc721.stop();
      notifyError("Error", "Something went wrong!");
      console.log(error);
      return undefined;
    }
  };
  return {
    approve,
    buyFixOrder,
    isApproved: approval.isApproved,
    loading: Task.buyFixPriceOrderErc721.isRunning(),
  };
};

/**
 *
 * @param _orderAsset
 *
 * Useage:
 *
 * Use to fetch single order of ERC721
 *
 * @returns
 *
 * - Return
 *
 * This hook returns Order interface and loading as boolean
 *
 */

export const useOrderERC721 = (_orderAsset: OrderAsset) => {
  const [order, setOrders] = useState<Order>();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  let response: ApiResponse<Order>;

  useEffect(() => {
    const singleOrder = async () => {
      Task.fetchErc721Order.start();
      try {
        response = await getOrderERC721(_orderAsset);

        if (response.data) {
          setOrders(response.data);
          notifySystem("ERC721 Order Get", response.message, NotificationType.SUCCESS);
        }
      } catch (e) {
        notifySystem("ERC721 Order Get", (e as any).response.data.message, NotificationType.ERROR);
      }
      Task.fetchErc721Order.stop();
      return response;
    };
    singleOrder();
  }, [_orderAsset.asset, _orderAsset.assetId]);

  return { order, loading: Task.fetchErc721Order.isRunning() };
};

/**
 *
 * @param _orderAsset
 *
 * Useage:
 *
 * Use to fetch single order of ERC1155
 *
 * @returns
 *
 * - Return
 *
 * This hook returns Order interface and loading as boolean
 *
 */
export const useOrderERC1155 = (_orderAsset: OrderAsset) => {
  const [order, setOrders] = useState<Order[]>();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  let response: ApiResponse<Order[]>;

  useEffect(() => {
    const fetch = async () => {
      Task.fetchErc1155Order.start();
      try {
        response = await getOrderERC1155(_orderAsset);

        if (response.data) {
          setOrders(response.data);
          notifySystem("ERC1155 Order Get", response.message, NotificationType.SUCCESS);
        }
      } catch (e) {
        notifySystem("ERC1155 Order Get", (e as any).response.data.message, NotificationType.ERROR);
      }
      Task.fetchErc1155Order.stop();
      return response;
    };
    fetch();
  }, [_orderAsset.asset, _orderAsset.assetId]);

  return { order, loading: Task.fetchErc1155Order.isRunning() };
};

/**
 *
 * @param saleKind:SaleKind(Enum)
 * @param page:number
 * @param limit:number
 * @param status:StatusType(Enum)
 *
 * Useage:
 *
 * Use to return all Orders
 *
 * @returns
 *
 * - This hook returns @interface AllOrders
 *
 * ```ts
 * AllOrders {
 * totalOrders?:number
 * totalPages?:number
 * error?:any
 * results:Order[]
 * }
 * ```
 */
export const useOrders = (saleKind: SaleKind, page: number, limit: number, status: StatusType) => {
  const [orders, setOrders] = useState<AllOrders>();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  let response: ApiResponse<AllOrders>;

  useEffect(() => {
    const fetchOrders = async () => {
      Task.fetchAllListedOrders.start();
      try {
        response = await getAllListedOrders(saleKind, page, limit, status);
        if (response.data) {
          setOrders(response.data);
          notifySystem("Fetch All Listed Orders", response.message, NotificationType.SUCCESS);
        }
      } catch (e) {
        console.log(e);
        notifySystem("Fetch All Listed Orders", (e as any).response.data.message, NotificationType.ERROR);
      }
      Task.fetchAllListedOrders.stop();
    };
    fetchOrders();
  }, [saleKind, page, limit, status]);

  return { orders, loading: Task.fetchAllListedOrders.isRunning() };
};

/**
 *
 * @param address :string
 * @param saleKind :SaleKind
 *
 *
 * Useage:
 *
 * Use to return all orders of specific collection depending on the saleKind
 *
 * @returns
 *
 * - Return values
 * this hook returns Array of Order and loading as boolean
 *
 */
export const useOrdersOfCollection = (address: string, saleKind: SaleKind) => {
  const [orders, setOrders] = useState<Order[]>();

  const { Task } = useConfig();

  const { notifySystem } = useNotify();

  let response: ApiResponse<Order[]>;

  useEffect(() => {
    const fetchOrdersForCollection = async () => {
      Task.fetchAllOrdersOfCollection.start();
      try {
        response = await getOrdersByCollection(address, saleKind);
        if (response.data) {
          setOrders(response.data);
          notifySystem("Fetch All Orders of Collection", response.message, NotificationType.SUCCESS);
        }
      } catch (e) {
        console.log(e);
        notifySystem("Fetch All Orders of Collection", (e as any).response.data.message, NotificationType.ERROR);
      }
      Task.fetchAllOrdersOfCollection.stop();
    };
    fetchOrdersForCollection();
  }, [address, saleKind]);

  return { orders, loading: Task.fetchAllOrdersOfCollection.isRunning() };
};

/**
 *
 * @param userAddress :string
 *
 * Useage:
 *
 * Use to return all orders of given user address
 *
 * @returns
 *
 * - Return values
 * this hook returns Array of Order and loading state as boolean
 */

export const useAllListedOrdersForAddress = (userAddress: string) => {
  const [orders, setOrders] = useState<Order[]>();

  const { notifySystem } = useNotify();
  const { Task } = useConfig();
  let response: ApiResponse<Order[]>;

  useEffect(() => {
    const fetchOrdersForAddress = async () => {
      Task.fetchAllListedOrders_BY_USER_ADDRESS.start();
      try {
        response = await getAllListedOrdersForAddress(userAddress);
        if (response.data) {
          setOrders(response.data);
          notifySystem("Fetch Listed Orders of Address", response.message, NotificationType.SUCCESS);
        }
      } catch (e) {
        console.log(e);
        notifySystem("Fetch Listed Orders of Address", (e as any).response.data.message, NotificationType.ERROR);
      }
      Task.fetchAllListedOrders_BY_USER_ADDRESS.stop();
    };
    fetchOrdersForAddress();
  }, [userAddress]);

  return { orders, loading: Task.fetchAllListedOrders_BY_USER_ADDRESS.isRunning() };
};

/**
 *
 * @returns
 * @function fetchSpecificOrders
 * @param SpecificOrderData
 *
 * Useage:
 *
 * Use to return all orders of specific collection
 *
 * ```ts
 * SpecificOrderData {
 * [key: string]: number[]
 * }
 *
 * ```
 * - Note:
 *
 * key is the collection address and number array is the array of tokenId's
 *
 *
 * @returns
 *
 * This hook returns Api response interface
 * where T is the array of Orders
 *
 * ```ts
 * ApiResponse<T> {
 * status: boolean
 * message: string
 * data?: T
 * }
 * ```
 */
export const useSpecificOrders = () => {
  const { notifySystem } = useNotify();
  let response: ApiResponse<Order[]>;
  const { Task } = useConfig();

  const fetchSpecificOrders = async (specificOrderData: SpecificOrderData) => {
    Task.fetchSpecificOrder.start();
    try {
      response = await getSpecificOrders(specificOrderData);
    } catch (e) {
      console.log(e);
      notifySystem("Fetch specific order", (e as any).response.data.message, NotificationType.ERROR);
    }
    Task.fetchSpecificOrder.stop();
    return response;
  };
  return { fetchSpecificOrders, loading: Task.fetchSpecificOrder.isRunning() };
};

/**
 *
 * @returns
 * @function useOrderHistory
 * @param OrderAsset
 *
 * Useage:
 *
 * Use to return sold orders history for a token
 *
 * ```ts
 * Order Asset {
 *   asset: string;
 *   assetId: number;
 * }
 *
 * ```
 * - Note:
 *
 *
 *
 * @returns
 *
 * This hook returns orders state as well as fethicng orders for manual use
 *
 * ```ts
 * }
 * ```
 */

export const useOrderHistory = (orderData: OrderAsset | undefined = undefined) => {
  const [orders, setOrders] = useState<HistoryOfOrder[]>();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  const fetchOrderHistory = async (orderDt: OrderAsset) => {
    Task.getOrderHistoryOfAsset.start();
    try {
      let res = await getOrderHistory(orderDt);
      setOrders(res.data);
      Task.getOrderHistoryOfAsset.stop();
      return res;
    } catch (error: any) {
      let errorMessage = "Error " + error?.response ? error?.response?.data?.error : "Error";
      notifySystem("Get order history", errorMessage, NotificationType.ERROR);
    }
    Task.getOrderHistoryOfAsset.stop();
  };

  useEffect(() => {
    orderData && fetchOrderHistory(orderData);
  }, [orderData?.asset, orderData?.assetId]);

  return { orders, loading: Task.getOrderHistoryOfAsset.isRunning(), fetchOrderHistory };
};

export const useBuyAnyOrder = () => {
  const { ethers, account } = useEthers();
  const exchange = useExchange();
  const { notifyError, notifyLoading, dismissNotification, notifySuccess } = useNotify();
  const { Task } = useConfig();

  const { checkManualApproval, approve } = useERC20Approval("", EXCHANGE_ADDRESS as string);

  const buyOrder = async (sellOrder: Order) => {
    if (!account || !sellOrder.order.asset || !sellOrder.order.paymentToken) return;

    Task.buyAnyOrder.start();
    let notiId = notifyLoading("Buy Order", "Please wait while we process your order");
    try {
      let isApproved = await checkManualApproval(sellOrder.order.paymentToken);
      if (!isApproved) {
        await approve(sellOrder.order.paymentToken);
      }
      let res = await hitListener();
      if (!res.value) {
        dismissNotification(notiId);
        notifyError("Buying Order", "Oops Server Error!");
        Task.buyAnyOrder.stop();
        return { error: "Internal Server Error!", status: false };
      }

      const buyOrder: OrderData = {
        ...sellOrder.order,
        side: OrderSide.BUY,
        maker: account,
        taker: sellOrder.order.maker,
        salt: Date.now().toString(),
      };
      const sellSig = splitSignature(sellOrder.signature as SignatureLike);
      if (!exchange) {
        dismissNotification(notiId);

        notifyError("Buying Order", "Unable to Sign Order!");
        Task.buyAnyOrder.stop();
        return { error: "Unable to Sign Order!", status: false };
      }
      // @ts-ignore
      const isValidParams = await exchange.validateOrderParameters(buyOrder);

      if (!isValidParams) {
        dismissNotification(notiId);
        notifyError("Buying Order", "Signed order is not Valid");

        Task.buyAnyOrder.stop();
        return { error: "Signed order is not Valid", status: false };
      }
      const txResponse = await awaitTransaction(
        // @ts-ignore
        exchange.atomicMatch(buyOrder, sellSig, sellOrder.order, sellSig, ZERO_ADDRESS, {
          value: sellOrder.order.paymentToken === ZERO_ADDRESS ? sellOrder.order.basePrice : null,
        })
      );
      if (txResponse.status) {
        notifySuccess("Buying Order", "Order bought successfully");
      } else {
        notifyError("Buying Order", "Opps Something Went Wrong!");
      }
      Task.buyAnyOrder.stop();
      dismissNotification(notiId);

      return txResponse;
    } catch (error) {
      dismissNotification(notiId);
      Task.buyAnyOrder.stop();
      notifyError("Buying Order", "Opps Something Went Wrong!");
      console.log(error);
      return undefined;
    }
  };

  return { buyOrder, loading: Task.buyAnyOrder.isRunning() };
};
