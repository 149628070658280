import { Typography, Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 25,
  },
  img: {
    width: 80,
    marginBottom: 10,
  },
}));

interface Props {
  imgUrl: string;
  title: string;
  value?: string;
  usdValue?: string;
  className: string;
}

const PriceStatsCard: React.FC<Props> = ({ imgUrl, title, value, className, usdValue }) => {
  const classes = useStyles();
  return (
    <div className={clsx(className, classes.root)}>
      <img src={imgUrl} className={classes.img} />
      <Typography variant="body1" component={"div"}>
        {title}
        <Typography
          variant="h4"
          fontFamily={"Oswald"}
          sx={title !== "ANTZ Price" ? { mt: 1, color: "text.primary" } : { mt: 1 }}
        >
          {value}
          {!!usdValue && (
            <Typography variant={value ? "h6" : "h4"} fontFamily={"Oswald"} sx={{ mt: 0 }}>
              $ {usdValue}
            </Typography>
          )}
        </Typography>
      </Typography>
    </div>
  );
};

export default PriceStatsCard;
