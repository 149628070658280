import { useEffect, useState } from "react";
import { NotificationType } from "../config/types";
import { getCategories } from "../api/settings";
import { useNotify } from "./useNotify";
import { useConfig } from "../contexts/nftVillageSdkContext";

/**
 * Use to fetch the categories of marketplace
 * @returns
 * This hook returns the categories as array of string and loading state as boolean
 */
export const useCategories = () => {
  const [categories, setCategories] = useState<string[]>();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  useEffect(() => {
    const fetch = async () => {
      Task.fetchCategories.start();
      try {
        const response = await getCategories();
        setCategories(response.data);
      } catch (e: any) {
        notifySystem("Fetch Categories", e.message, NotificationType.ERROR);
      }
      Task.fetchCategories.stop();
    };
    fetch();
  }, []);

  return { categories, loading: Task.fetchCategories.isRunning() };
};
