import { useEffect, useState } from "react";
import { useNotify } from "./useNotify";

import {
  Token,
  ApiResponse,
  NotificationType,
  CreateItemState,
  Order,
  OrderSide,
  AssetType,
  SaleKind,
} from "../config/types";
import { getAllListedTokensForAddress } from "../api/token";
import { useConfig } from "../contexts/nftVillageSdkContext";
import { useMintERC721 } from "./useMintToken";
import { useERC1155Approval } from "@react-dapp/utils";
import { useSellOrder } from "./useOrder";
import { EXCHANGE_ADDRESS } from "../config/config";

/**
 *
 * @param userAddress :string
 *
 * Useage:
 *
 * Use to return all orders of given user address
 *
 * @returns
 *
 * - Return values
 * this hook returns Array of Order and loading state as boolean
 */

export const useAllAvailableTokensForAddress = (userAddress: string) => {
  const [tokens, setTokens] = useState<Token[]>();
  const { Task } = useConfig();
  const { notifySystem } = useNotify();

  let response: ApiResponse<Token[]>;

  useEffect(() => {
    const fetchTokensForAddress = async () => {
      try {
        Task.fetchTokensOfUserAddress.start();

        response = await getAllListedTokensForAddress(userAddress);
        console.log("res", response);
        if (response.data) {
          setTokens(response.data);
          notifySystem("Fetch tokens for address", response.message, NotificationType.SUCCESS);
          Task.fetchTokensOfUserAddress.stop();
        }
      } catch (e) {
        console.log(e);
        Task.fetchTokensOfUserAddress.stop();
        notifySystem("Fetch tokens for address", (e as any).response.data.message, NotificationType.ERROR);
      }
    };
    fetchTokensForAddress();
  }, [userAddress]);

  return { tokens, loading: Task.fetchTokensOfUserAddress.isRunning() };
};

export const useERC721TokenListingFlow = ({
  tokenData,
  asset,
  isOpen,
}: {
  tokenData: CreateItemState;
  asset?: string;
  isOpen: boolean;
}) => {
  type Task = "loading" | "completed" | "stopped" | "waiting";
  const { notifySuccess, notifyError } = useNotify();

  const [minting, setMinting] = useState<Task>("waiting");
  const [approving, setApproving] = useState<Task>("waiting");
  const [signing, setSigning] = useState<Task>("waiting");
  const { mintToken: createToken, readyToUse } = useMintERC721(tokenData);

  const { isApproved, checkManualApproval, approve } = useERC1155Approval(asset || "", EXCHANGE_ADDRESS || "");

  const { create } = useSellOrder(asset || "");
  const [token, setToken] = useState<Token | undefined>(undefined);

  useEffect(() => {
    if (isOpen && readyToUse) {
      setMinting("loading");
    }
  }, [isOpen, readyToUse]);

  useEffect(() => {
    minting === "loading" && startMinting();
  }, [minting]);

  useEffect(() => {
    approving === "loading" && startApproving();
  }, [approving]);

  useEffect(() => {
    signing === "loading" && startSigning();
  }, [signing]);

  const startMinting = async () => {
    let tkn = await createToken();
    if (tkn?.data) setToken(tkn?.data);
    else {
      setMinting("waiting");
      notifyError("Mint token", "Failed to mint token");
      return;
    }
    setMinting("completed");
    setApproving("loading");
  };

  const startApproving = async () => {
    let isApproved = await checkManualApproval(token?.address || "");
    if (!isApproved) {
      let res = await approve(token?.address);
      if (res) {
        setApproving("completed");
        if (signing === "waiting") setSigning("loading");
      } else {
        setApproving("stopped");
        notifyError("Mint token", "Failed to approve");
      }
    } else {
      setApproving("completed");
      setSigning("loading");
    }
  };

  const startSigning = async (tkn: Token | undefined = undefined) => {
    let t: any = tkn || token;
    if (t && t.metadata) {
      let ord: Order = {
        order: {
          asset: t?.address,
          assetId: t.tokenId || 1,
          maker: t.minter,
          side: OrderSide.SELL,
          assetType: AssetType.ERC721,
          saleKind: SaleKind.BUYNOW,
          basePrice: tokenData?.fixedPrice?.toString() || "1",
        },
        metadata: {
          ...t.metadata,
          price: tokenData?.fixedPrice || 1,
        },
      };
      let order = await create(ord);
      setSigning("completed");
      if (!order) {
        setSigning("stopped");
        notifyError("Creating Order", "Failed to create order");
        return;
      }
      notifySuccess("Listing", "Listed successfully");
    }
  };

  return {
    minting,
    startMinting: () => setMinting("loading"),
    approving,
    startApproving: () => setApproving("loading"),
    signing,
    startSigning: () => setSigning("loading"),
    token,
  };
};
