import { Button, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    gap: 20,
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 5,
    marginBottom: 5,
    height: 55,
  },
}));

const Deposit = ({ pool }: { pool: any }) => {
  const classes = useStyles();
  return (
    <>
      <Typography component={"small"}>2% deposit fee</Typography>
      <div className={classes.textField}>
        <Box sx={{ background: "#516a87", opacity: 0.6, display: "inline-block", flex: 2 }}>
          <TextField
            value={pool?.depositInfo.input.value}
            onChange={(e) => pool?.depositInfo.input.setValue(e.target.value)}
            variant="outlined"
            sx={{ width: "100%" }}
          />
        </Box>
        <Button
          variant="contained"
          disableElevation
          onClick={() => pool?.depositInfo.input.selectMaxValue()}
          sx={{ height: "100%", backgroundColor: "#516a87", backgroundBlendMode: "luminosity" }}
        >
          Max
        </Button>
      </div>
      <Typography component={"small"}>Balance: {pool?.stakedTokenBalance} $ANTZ</Typography>
      <hr />
      <div
        style={{
          background: "linear-gradient(180deg, #5E4BC5 0%, #7C69E3 100%)",
          padding: "4px",
          borderRadius: "8px",
          display: "inline-block",
          marginTop: 7,
        }}
      >
        <Button
          onClick={() =>
            pool?.stakedTokenApproval.isApproved ? pool?.depositInfo.deposit() : pool?.stakedTokenApproval.approve()
          }
          variant="contained"
          sx={{ pl: 10, pr: 10, background: "linear-gradient(180deg, #7C69E3 0%, #46398E 100%)", border: "8px" }}
        >
          {pool?.stakedTokenApproval.approvePending || pool?.depositInfo.pending
            ? "Pending..."
            : !pool?.stakedTokenApproval.isApproved
            ? "Approve"
            : "Deposit"}
        </Button>
      </div>
    </>
  );
};

export default Deposit;
