import { Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  stats: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
}));

interface Props {
  imgUrl: string;
  title: string;
  value: string;
}

const Stats: React.FC<Props> = ({ imgUrl, title, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.stats}>
      <img src={imgUrl} width="80" />
      <Typography component={"div"} variant="body2">
        {title}
        <Typography variant="h5" fontFamily={"'Oswald'"}>
          {value}
          <Typography>ANTZ</Typography>
        </Typography>
      </Typography>
    </div>
  );
};

export default Stats;
