import React from "react";
import { Route, Routes as Switch, useLocation } from "react-router-dom";
import Navbar from "src/components/Navbar/Navbar";
import Footer from "src/components/Footer/Footer";
import NotFound from "src/pages/NotFound/NotFound";
import DrawerNav from "./components/Drawer/Drawer";
import NFTStaking from "./pages/NFTStaking/NFTStaking";
import Dashboard from "./pages/Dashbord/Dashboard";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import Staking from "./pages/Staking/Staking";
import { useEagerConnect } from "@react-dapp/wallet";
import Minting from "./pages/Minting/Minting";
import Account from "./pages/Account/Account";
import { UserNFTDetail } from "./pages/UserNFTDetail/UserNFTDetail";
import Leaderboard from "./pages/Leaderboard/Leaderboard";

interface Props {}

const Routes: React.FC<Props> = () => {
  useEagerConnect();
  const { pathname } = useLocation();

  return (
    <div style={{ minHeight: "100vh", display: "grid", gridTemplateRows: "min-content 1fr min-content" }}>
      <Navbar />
      <DrawerNav />
      <Switch>
        <Route path="/" element={<Dashboard />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/account" element={<Account />} />
        <Route path="/minting" element={<Minting />} />
        <Route path="/nft-staking" element={<NFTStaking />} />
        <Route path="/account/:tokenId" element={<UserNFTDetail />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="*" element={<ComingSoon />} />
      </Switch>

      {!pathname.includes("/minting") && <Footer />}
    </div>
  );
};

export default Routes;
