import { createSlice } from "@reduxjs/toolkit";
// import { useMediaQuery } from "@mui/material";
import theme from "src/utils/theme";

interface StateInterface {
  open: boolean;
}

const initialState: StateInterface = {
  open: true,
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState: initialState,
  reducers: {
    setOpen: (state: StateInterface, action: any) => {
      state.open = action.payload;
    },
  },
});

export const { setOpen } = drawerSlice.actions;

export default drawerSlice.reducer;
