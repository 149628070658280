import axios from "axios";
import { BASE_API_URL, isProduction } from "./config";

export const TOKEN_IDS_API_URL = isProduction
  ? "https://gameantz-metadata-api.herokuapp.com/api"
  : "http://localhost:5000/api";
export const GAME_ANTZ_METADATA_API_URL = "https://cloud.gameantz.com/api";

export const apiCall = axios.create({
  baseURL: BASE_API_URL,
  timeout: 100000,
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
});

export const metadataApiCall = axios.create({
  baseURL: TOKEN_IDS_API_URL,
  timeout: 100000,
});

export const accountApiCall = axios.create({
  baseURL: TOKEN_IDS_API_URL,
  timeout: 100000,
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
});

export const metaDataApiCall = axios.create({
  baseURL: GAME_ANTZ_METADATA_API_URL,
  timeout: 100000,
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
});
