import { configureStore } from "@reduxjs/toolkit";
import { reducer as notificationsReducer } from "reapop";
import userReducer from "./user/userReducer";
import drawerReducer from "./drawer/drawerReducer";
import { useDispatch } from "react-redux";
import { poolReducer } from "@nftvillage/farms-sdk";

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    notifications: notificationsReducer(),
    user: userReducer,
    drawer: drawerReducer,
    pools: poolReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
