import BigNumber from "bignumber.js";

export interface UtilsConfig {
    provider: any,
    rpcUrl?: string,
    wrappedNative?: Token,
    usd?: Token,
    nativeUsdLp?: Token
}

export enum STATE {
    IDLE,
    BUSY,
    FAILED,
    SUCCEED
};

export enum TokenStandard {
    ERC20,
    ERC721,
    ERC1155
}

export interface Token {
    address: string
    name: string,
    symbol: string,
    decimals: number,
    totalSupply?: BigNumber,
    totalBurned?: BigNumber,
    balance?: BigNumber
}

export interface LPToken extends Token {
    token0: string,
    token1: string,
    qouteTokenBalance: BigNumber,
    baseTokenBalance: BigNumber,
    qouteToken: Token,
    baseToken: Token,
    price: {
        qouteTokenPrice: BigNumber,
        baseTokenPrice: BigNumber,
    }
}

export interface TokenBalance {
    account: string,
    tokenId: number,
    amount: number
}