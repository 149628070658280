import { ContractFactory, ContractInterface, Signer } from "ethers";
import { CollectionData, NotificationType } from "../config/types";
import { useEthers, ERC1155_ABI, awaitTransaction } from "@react-dapp/utils";
import bytecode from "../assets/bytecode/index.json";
import { useNotify } from "./useNotify";
import { useConfig } from "../contexts/nftVillageSdkContext";
import abi from "../assets/abi/index.json";

const _deployContract = (
  contractAbi: ContractInterface,
  contractByteCode: any,
  contractArgs: any[],
  signer: Signer
) => {
  const factory = new ContractFactory(contractAbi, contractByteCode, signer);
  return factory.deploy(...contractArgs);
};

export const useDeployERC721 = () => {
  const { signer, account } = useEthers();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  const deploy = async (collection: CollectionData) => {
    let contract;
    collection = { ...collection, tokenUri: (process.env.BASE_METADATA_URI as string) + collection?.shortUrl + "/" };
    if (signer && account) {
      try {
        const { name, symbol, tokenUri } = collection;
        Task.deployErc721Contract.start();
        contract = await _deployContract(
          abi.erc721,
          bytecode.erc721,
          [name ? `${name}` : "", symbol ? `${symbol}` : "", `${tokenUri}`],
          signer
        );
        // Wait for deployed contract to be mined
        await contract.deployTransaction.wait();

        notifySystem("ERC721 Deploy", "Contract Deployed Successfully", NotificationType.SUCCESS);
      } catch (error) {
        notifySystem("ERC721 Deploy", "Unable to deploy!", NotificationType.ERROR);
      }
      Task.deployErc721Contract.stop();
    }
    return contract;
  };
  return { deploy, txPending: Task.deployErc721Contract.isRunning() };
};

export const useDeployERC1155 = () => {
  const { signer, account } = useEthers();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  const deploy = async (collection: CollectionData) => {
    if (signer && account) {
      const { name, symbol, tokenUri } = collection;

      let contract;
      Task.deployErc1155Contract.start();

      try {
        contract = await _deployContract(
          ERC1155_ABI,
          bytecode.erc1155,
          [
            name ? `${name}` : "",
            symbol ? `${symbol}` : "",
            account,
            "0x0000000000000000000000000000000000000000",
            tokenUri,
            "",
          ],
          signer
        );

        notifySystem("ERC1155 Deploy", "Contract Deployed Successfully", NotificationType.SUCCESS);
      } catch (e) {
        console.log(e);
        notifySystem("ERC1155 Deploy", "Unable to deploy!", NotificationType.ERROR);
      }
      Task.deployErc1155Contract.stop();

      return contract;
    }
  };
  return { deploy, txPending: Task.deployErc1155Contract.isRunning() };
};
