import React from "react";
import { useERC1155Balance } from "@react-dapp/utils";
import { useFetchMetadataForTokenIdsERC1155 } from "./useMetadata";
import { useConfig } from "../contexts/nftVillageSdkContext";

export const useInventoryERC1155 = (
  erc1155Address: string,
  totalTokenIds: number,
  addressUri = "",
  userAddress?: string
) => {
  const tokenIds = new Array(totalTokenIds).fill(0, 0, totalTokenIds).map((v, i) => i);
  const { Task } = useConfig();
  const [results, setResults] = React.useState<any>([]);
  const { fetchAllMetadata } = useFetchMetadataForTokenIdsERC1155(erc1155Address, addressUri);

  const { balance, loading } = useERC1155Balance(erc1155Address || "", tokenIds, userAddress);

  React.useEffect(() => {
    if (!Task.fetchingInventory.isRunning() && loading) Task.fetchingInventory.start();
    if (!Task.fetchingInventory.isRunning() && !loading) Task.fetchingInventory.stop();
  }, [loading]);

  React.useEffect(() => {
    const fetchMetaData = async () => {
      Task.fetchingInventory.start();
      let arr =
        balance
          ?.filter((e: any) => e && e.amount && e.amount > 0)
          ?.map((e: any) => {
            return {
              amount: e.amount,
              tokenId: e.tokenId,
            };
          }) || [];

      let res: any = await fetchAllMetadata(arr.map((e) => e.tokenId));
      res = res.map((item: any, i: number) => ({
        ...item,
        amount: arr[i]?.amount,
      }));

      setResults(res);
      Task.fetchingInventory.stop();
    };
    if (balance && balance?.length > 0) fetchMetaData();
  }, [balance]);

  return {
    loading: Task.fetchingInventory.isRunning(),
    results,
  };
};
