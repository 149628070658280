import { useState, useEffect } from "react";
import { awaitTransaction, toLowerUnit, useContract, useERC721, useEthers } from "@react-dapp/utils";
import { Token, AssetType, ApiResponse, NotificationType } from "../config/types";
import { createToken, getSignedToken, SignedToken, tokenId, Signature } from "../api/token";
import { useNotify } from "./useNotify";
import { useConfig } from "../contexts/nftVillageSdkContext";
import abi from "../assets/abi/index.json";
import * as ethers from "ethers";
import { NFTVillageERC721 } from "../types/tokens/NFTVillageERC721";

/**
 * Use to mint erc721 tokens
 * @param data:Tokens
 *
 * ```ts
 * Token {
 * address: string
 * metadata: MetaData
 * fees: Fee[]
 * minter: string
 * owner: string
 * }
 * ```
 * @returns this hook returns the following...
 * @function mintToken()
 * @property tokenId:number
 *
 * @function mintToken()
 * @returns
 *
 * This hook returns Api response interface
 * where T is the Token interface
 * ```ts
 * ApiResponse<T> {
 * status: boolean
 * message: string
 * data?: T
 * }
 * ```
 *
 */
export const useMintERC721 = (data: Token) => {
  const { account } = useEthers();
  const contract = useContract<NFTVillageERC721>(abi.erc721, data.address);
  const { notifySystem } = useNotify();
  const [tokenId, setTokenId] = useState<number>();
  const [readyToUse, setReadyToUse] = useState(false);
  const { Task } = useConfig();

  let responseDataTokenId: ApiResponse<tokenId>;
  let responseDataSignature: ApiResponse<Signature>;
  let responseDataToken: ApiResponse<Token>;

  useEffect(() => {
    if (contract && account && data.address) {
      setReadyToUse(true);
    } else {
      setReadyToUse(false);
    }
  }, [contract, account]);

  const mintToken = async () => {
    if (!contract || !account) {
      notifySystem("Mint ERC721 Token", "Account not found!", NotificationType.ERROR);
      return;
    }
    Task.mintErc721Token.start();
    const owner = await contract.owner();

    if (owner === data.minter) {
      try {
        try {
          let receipt = await (await contract["mint(address,(address,uint256)[])"](account, data.fees)).wait();
          let iface = new ethers.utils.Interface(abi.erc721);
          let args = iface.parseLog(receipt.logs[receipt.logs.length - 1]).args;
          let _tokenId = args?.tokenId.toNumber();
          setTokenId(_tokenId);
          try {
            responseDataToken = await createToken({
              ...data,
              tokenId: _tokenId,
              fees: data.fees,
            });
          } catch (e) {
            notifySystem("Mint ERC721 Token", (e as any).response.data.message, NotificationType.ERROR);
          }
        } catch (error) {
          console.log(error);
          notifySystem("Mint ERC721 Token", "Unable to mint token !", NotificationType.ERROR);
        }
      } catch (e) {
        console.log(e);
        notifySystem("Mint ERC721 Token", (e as any).response.data.message, NotificationType.ERROR);
      }
    }
    Task.mintErc721Token.stop();

    return responseDataToken;
  };
  return { tokenId, mintToken, readyToUse, loading: Task.mintErc721Token.isRunning() };
};
