import { Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useERC1155 } from "@react-dapp/utils";
import { useWallet } from "@react-dapp/wallet";
import React from "react";
import { useSelector } from "react-redux";
import coins from "src/assets/icons/coins.png";
import nftcount from "src/assets/icons/nftcount.png";
import NFTCard from "src/components/NFTCard/NFTCard";
import { ERC1155_ADDRESS } from "src/config/config";
import useNotify from "src/hooks/useNotify";
import { RootState } from "src/state";
import { IModalData } from "src/types/types";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "70vw",
    position: "relative",
    right: "-6%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    margin: "50px auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      right: 0,
    },
  },
  stats: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 40,
    gap: 100,
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      gap: 30,
      justifyContent: "center",
    },
  },
  textContainer: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    "& > img": {
      width: 70,
    },
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: 20,
    marginTop: 50,
    minHeight: "250px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  modalBox: {
    borderRadius: 10,
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#181728",
    border: `2px solid #fff`,
    [theme.breakpoints.down("md")]: {
      width: "90vw",
    },
  },
}));

const Account = () => {
  const classes = useStyles();
  const { balance, nftBalance, metadatas } = useSelector((state: RootState) => state.user.user);
  const [loading, setLoading] = React.useState(false);
  const { notifySuccess, notifyError } = useNotify();

  const erc1155Contract = useERC1155(ERC1155_ADDRESS as string);
  const { account } = useWallet();

  const transferAmount = async (data: IModalData) => {
    try {
      setLoading(true);
      console.log("account", account);
      const txn = await erc1155Contract?.safeTransferFrom(account, data.userAddress, data.tokenId, data.amount, "0x");
      await txn.wait();
      notifySuccess("Success", "Transfer Successful");
      console.log("Transfer successful");
    } catch (error) {
      console.log("Error", error);
      notifyError("Error", "Error Transfering amount");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.stats}>
        <div className={classes.textContainer}>
          <img src={coins} />
          <div>
            <Typography>Balance</Typography>
            <Typography variant="h4" fontWeight={600}>
              {balance}
            </Typography>
          </div>
        </div>
        <div className={classes.textContainer}>
          <img src={nftcount} />
          <div>
            <Typography>NFT Balance</Typography>
            <Typography variant="h4" fontWeight={600}>
              {nftBalance}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.cardsContainer}>
        {metadatas.map((nft, i) => (
          <NFTCard
            key={i}
            name={nft.name}
            image={nft.image}
            tokenId={nft.tokenId}
            amount={nft.amount}
            transferAmount={transferAmount}
            loading={loading}
          />
        ))}

        {/* <NFTCard
          name={"Name"}
          image={"https://res.cloudinary.com/dp2w71iv6/image/upload/v1658427699/nft_images/nft_1034.jpg"}
          tokenId={0}
          amount={2}
          transferAmount={transferAmount}
          loading={loading}
        /> */}
      </div>
    </div>
  );
};

export default Account;
