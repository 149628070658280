import { metadataApiCall } from "src/config/apiConfig";

interface Leaderboard {
    id:string;
    lastBuy:string;
    value:string;
}

export const getLeaderboard = async () => {
  const { data:{leaderboards} } = await metadataApiCall.get("/leaderboard");
  return leaderboards as Leaderboard[];
};
