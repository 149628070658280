import { useERC1155 } from "@react-dapp/utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { NotificationType } from "../config/types";
import { useNotify } from "./useNotify";

/**
 * Fetch IPFS data
 *
 * @param ipfsUrl IPFS url of the metadata
 * @returns json object of the metadata
 */
export const fetchIpfs = (ipfsUrl: string) =>
  new Promise(async (resolve) => {
    let url = `https://ipfs.io/${ipfsUrl.replace("ipfs://", "")}`;
    let { data } = await axios.get(url);
    if (data?.image?.includes("ipfs://")) {
      data.image = `https://ipfs.io/${data.image.replace("ipfs://", "")}`;
    }
    resolve(data);
  });

/**
 * This hook fetches the metadata of an ERC1155 token.
 *
 * @param contractAddress Address of contract
 *
 */

export const useFetchMetadata = (contractAddress: string) => {
  const [metadata, setMetadata] = useState<any>();
  let contract = useERC1155(contractAddress);
  const { notifySystem } = useNotify();

  /**
   * Fetch IPFS data
   *
   * @param ipfsUrl IPFS url of the metadata
   * @returns json object of the metadata
   */
  const fetchIpfs = (ipfsUrl: string) =>
    new Promise(async (resolve) => {
      let url = `https://ipfs.io/ipfs/${ipfsUrl.replace("ipfs://", "")}`;
      let { data } = await axios.get(url);
      if (data?.image?.includes("ipfs://")) {
        data.image = `https://ipfs.io/ipfs/${data.image.replace("ipfs://", "")}`;
      }
      resolve(data);
    });

  //   const recursivefn = async (object: any) => {
  //     if (typeof object === "object") {
  //       for (const key in object) {
  //         object[key] = await recursivefn(object[key]);
  //       }
  //     } else if (typeof object === "string") {
  //       if (object.startsWith("ipfs://")) {
  //         object = await fetchIpfs(object);
  //         object = await recursivefn(object);
  //       }
  //     }
  //     return object;
  //   };

  /**
   * Fetch Metadata of the token
   *
   * @param tokenId Token id of the token
   * @returns Metadata of the token
   */
  const fetchMetadata = async (tokenId: string) => {
    try {
      let uri: string = await contract?.uri(tokenId);
      uri = uri.replaceAll("{id}", tokenId);
      let data;
      if (uri.includes("ipfs://")) {
        data = await fetchIpfs(uri);
        setMetadata(data);
      } else {
        let res = await axios.get(uri);
        data = res.data;
      }
      notifySystem("Fetch Single Metadata", "Metadata fetched", NotificationType.SUCCESS);
      return { ...data, address: contractAddress };
    } catch (error) {
      console.error(error);
      notifySystem("Fetch Single Metadata", "Unable to fetch metadata", NotificationType.ERROR);
    }
  };
  return { metadata, fetchMetadata };
};

export const useFetchMetadataForTokenIdsERC1155 = (POOL_CARD_ADDRESS: string, addressUri = "") => {
  let contract = useERC1155(POOL_CARD_ADDRESS);

  const fetch = (tokenId: number) =>
    new Promise(async (resolve, reject) => {
      try {
        if (!contract) return;
        let uri: string = addressUri || (await contract.uri(tokenId));
        uri = uri.replaceAll("{address}", POOL_CARD_ADDRESS);
        uri = uri.replaceAll("{id}", tokenId.toString());
        let data;
        if (uri.includes("ipfs://")) {
          data = await fetchIpfs(uri);
        } else {
          let res = await axios.get(uri);
          data = res.data;
        }
        resolve({ ...data, address: POOL_CARD_ADDRESS });
      } catch (error) {
        resolve({ name: "", image: "" });
      }
    });

  const fetchAllMetadata = async (tokenIds: number[]) => {
    let allData = [];
    let promises: Promise<unknown>[] = [];
    tokenIds.forEach((tokenId, i) => {
      let promise = fetch(tokenId);
      promises[i] = promise;
    });
    allData = await Promise.all(promises);
    return allData;
  };

  return { fetchAllMetadata };
};
