import { relayerApi } from "../config/config";
import { Token, Fee, AssetType, ApiResponse } from "../config/types";

const handleFormInput = (data: any, key: string, formData: FormData) => {
  if (Array.isArray(data)) {
    data.forEach((item, index) => {
      handleFormInput(item, `${key}[${index}]`, formData);
    });
  } else if (data instanceof File || data instanceof Blob) {
    formData.append(key, data);
  } else if (typeof data === "object") {
    for (const [k, v] of Object.entries(data)) {
      handleFormInput(v, `${key}[${k}]`, formData);
    }
  } else {
    formData.append(key, data);
  }
};

export const shapeFormData = (data: any) => {
  let formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    handleFormInput(value, key, formData);
  }
  return formData;
};


export interface SignedToken {
  minter: string;
  fees: Fee[];
  address: string;
  standard: AssetType.ERC721;
}
export type Signature = {
  id: number;
  sig: string;
};
export type tokenId = number;

export const getLatestTokenId = async (
  address: string
): Promise<ApiResponse<tokenId>> => {
  const rawResponse = await relayerApi.get(`/tokens/latest-id/${address}`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.tokenId,
  };
};

export const createToken = async (
  token: Token
): Promise<ApiResponse<Token>> => {
  const rawResponse = await relayerApi.post(`/tokens`, shapeFormData(token));
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.token,
  };
};

export const getSignedToken = async (
  data: SignedToken
): Promise<ApiResponse<Signature>> => {
  const rawResponse = await relayerApi.post(`/tokens/getsignedtoken`, data);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data,
  };
};

export const getAllListedTokensForAddress = async (
  userAddress: string
): Promise<ApiResponse<any[]>> => {
  const rawResponse = await relayerApi.get(`tokens/user/${userAddress}`);
  console.log("raw",rawResponse);
  const response = rawResponse.data.data;
  const allTokens = response.tokens;
  let tokens: Token[] = [];
  allTokens.forEach((token: any, index: number) => {
    tokens[index] = {
      address: token.address,
      tokenId: token.tokenId,
      fees: token.fees,
      minter: token.minter,
      owner: token.owner,
      tokenUri: token.tokenUri,
      metadata: {
        ...token,
      },
    };
  });
  console.log("tokens",tokens);
  return {
    message: response.message,
    status: response.status,
    data: tokens,
  };
};
