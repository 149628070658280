import { useERC1155, useERC721, useERC20 } from "./useContract";
import { useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import { ZERO_ADDRESS, MAX_UINT } from "../config/constants";
import { STATE } from "../config/types";
import { useEthers } from "./useEthers";
import { awaitTransaction, toBigNumber } from "../utils";
import { Contract } from "ethers";
import ERC20_ABI from "../assets/abi/erc20Abi.json";
import ERC721_ABI from "../assets/abi/erc721Abi.json";
import ERC1155_ABI from "../assets/abi/erc1155Abi.json";

export const ZERO_BALANCE = new BigNumber(0);

export const useERC20Approval = (erc20Address: string | undefined, operator: string | undefined, requiredApprovedBalance: BigNumber = new BigNumber(1)) => {
  const [approvedBalance, setApprovedBalance] = useState(ZERO_BALANCE);
  const [approveState, setApproveState] = useState(STATE.IDLE);
  const [txPending, setTxPending] = useState(false);

  const [isApproved, setIsApproved] = useState(false);

  const { account, signer } = useEthers();
  const token = useERC20(erc20Address);

  const fetchApprovedBalance = async () => {
    if (account && token) {
      if (erc20Address === ZERO_ADDRESS) {
        setIsApproved(true);
        return;
      }
      try {
        const bal = await token.allowance(account, operator);
        const approveBal = toBigNumber(bal);
        setApprovedBalance(approveBal);
        setIsApproved(approveBal.gte(requiredApprovedBalance));
      } catch (e) {
        console.log(e);
      }
    }
  };
  useEffect(() => {
    fetchApprovedBalance();
  }, [account, token]);

  const approve = async (address: string | undefined = undefined) => {
    try {
      setTxPending(true);
      setApproveState(STATE.BUSY);

      if (address) {
        const _contract = new Contract(address, ERC20_ABI, signer);
        const response = await awaitTransaction(_contract.approve(operator, MAX_UINT));
        if (response.status) setIsApproved(true);
      } else {
        if (token) {
          const response = await awaitTransaction(token.approve(operator, MAX_UINT));
          if (response.status)
            await fetchApprovedBalance();
        } else {
          throw new Error("Provide contract address");
        }
      }
      setApproveState(STATE.SUCCEED);
      setTxPending(false);
    } catch (e) {
      console.log(e);
      setTxPending(false);
      setApproveState(STATE.FAILED);
    }
  };

  const checkManualApproval = async (contractAddress: string) => {
    if (contractAddress === ZERO_ADDRESS) {
      return true;
    }
    const _contract = new Contract(contractAddress, ERC20_ABI, signer);
    const bal = await _contract.allowance(account, operator);
    const approveBal = toBigNumber(bal);
    setApprovedBalance(approveBal);
    const _isApproved = approveBal.gte(requiredApprovedBalance);
    return _isApproved;
  };

  return { approvedBalance, isApproved, txPending, approveState, checkManualApproval, approve };
};

export const useERC721Approval = (erc721Address: string | undefined, operator: string) => {
  const [isApproved, setIsApproved] = useState(false);
  const [approveState, setApproveState] = useState(STATE.BUSY);
  const [txPending, setTxPending] = useState(false);
  const { account, signer, ethers } = useEthers();
  const contract = useERC721(erc721Address);

  const fetchApproved = async () => {
    if (account && contract) {
      setApproveState(STATE.BUSY);
      const _isApproved = await contract.isApprovedForAll(account, operator);
      setIsApproved(_isApproved);
      setApproveState(STATE.SUCCEED);
    }
  };

  useEffect(() => {
    fetchApproved();
  }, [account, contract]);

  const approve = async (address: string | undefined = undefined) => {
    try {
      setTxPending(true);
      if (address) {
        const _contract = new Contract(address, ERC721_ABI, signer);
        await awaitTransaction(_contract.setApprovalForAll(operator, true));
        setIsApproved(true);
      } else {
        if (contract) {
          await awaitTransaction(contract.setApprovalForAll(operator, true));
          await fetchApproved();
          setTxPending(false);
          return true;
        } else {
          throw new Error("Provide contract address");
        }
      }
      return false;
    } catch (e) {
      console.log(e);
      setTxPending(false);
      return false;
    }
  };

  const checkManualApproval = async (address: string) => {
    const _contract = new Contract(address, ERC721_ABI, signer ?? ethers);
    const _isApproved = await _contract.isApprovedForAll(account, operator);
    return _isApproved;
  };

  return { isApproved, approveState, txPending, approve, checkManualApproval };
};

export const useERC1155Approval = (erc1155Address: string | undefined, operator: string) => {
  const [isApproved, setIsApproved] = useState(false);
  const [approveState, setApproveState] = useState(STATE.BUSY);
  const [txPending, setTxPending] = useState(false);
  const { account, signer, ethers } = useEthers();
  const contract = useERC1155(erc1155Address);

  const fetchApproved = async () => {
    if (account && contract) {
      setApproveState(STATE.BUSY);
      const _isApproved = await contract.isApprovedForAll(account, operator);
      setIsApproved(_isApproved);
      setApproveState(STATE.SUCCEED);
    }
  };

  useEffect(() => {
    fetchApproved();
  }, [account, contract]);

  const approve = async (address: string | undefined = undefined) => {
    try {
      if (address) {
        const _contract = new Contract(address, ERC1155_ABI, signer);
        setTxPending(true);
        let res = await awaitTransaction(_contract.setApprovalForAll(operator, true));
        setTxPending(false);
        setIsApproved(res.status);
        return res.status;
      } else {
        if (contract) {
          setTxPending(true);
          let res = await awaitTransaction(contract.setApprovalForAll(operator, true));
          await fetchApproved();
          setTxPending(false);
          return res.status;
        } else {
          throw new Error("Provide contract address");
        }
      }
    } catch (e) {
      console.log(e);
      setTxPending(false);
      return false;
    }
  };

  const checkManualApproval = async (address: string) => {
    const _contract = new Contract(address, ERC1155_ABI, signer ?? ethers);
    const _isApproved = await _contract.isApprovedForAll(account, operator);
    return _isApproved;
  };

  return { isApproved, approveState, txPending, checkManualApproval, approve };
};
