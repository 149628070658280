import { ethers } from "ethers";
import { nft_sale_abi } from "src/contracts/abi/NFTSale";

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const isProduction = process.env.REACT_APP_PRODUCTION === "true";

// require('dotenv').config()

export const BASE_RELAYER_URL = process.env.REACT_APP_BASE_RELAYER_URL as string;
export const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS as string;
export const PAIR_ADDRESS = process.env.REACT_APP_PAIR_ADDRESS as string;
export const NFT_ADDRESS = process.env.REACT_APP_NFT as string;
export const NFT_SALE_ADDRESS = process.env.REACT_APP_NFT_SALE as string;
export const ERC1155_ADDRESS = process.env.REACT_APP_POOL_CARDS_ADDRESS as string;

export const MAX_AVAILABLE_PACKS = [35, 35, 35, 40, 27, 27, 27, 29, 12, 12, 11, 4, 4, 5, 11];

export const TOKEN_STAKING_POOL_IDS = [0];
export const NFT_STAKING_POOL_IDS = [0, 1, 2, 3, 4, 5, 6];

export const getProviderOrSigner = (needSigner = false) => {
  // Connect to Metamask
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  if (needSigner) {
    const signer = provider.getSigner();
    return signer;
  }
  return provider;
};
