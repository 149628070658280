import React from "react";
import { Button, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    gap: 20,
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15,
    marginBottom: 5,
    height: 55,
  },
}));

const Withdraw = ({ pool }: { pool: any }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.textField}>
        <Box sx={{ background: "#516a87", opacity: 0.6, display: "inline-block", flex: 2 }}>
          <TextField
            value={pool?.withdrawInfo.input.value}
            onChange={(e) => pool?.withdrawInfo.input.setValue(e.target.value)}
            variant="outlined"
            sx={{ width: "100%" }}
          />
        </Box>
        <Button
          onClick={() => pool?.withdrawInfo.input.selectMaxValue()}
          disableElevation
          variant="contained"
          sx={{ height: "100%", backgroundColor: "#516a87" }}
        >
          Max
        </Button>
      </div>
      <Typography component={"small"}>Your Stack {pool?.stakedAmount} $ANTZ</Typography>
      <hr />
      <Button onClick={() => pool?.withdrawInfo.withdraw()} variant="contained" sx={{ pl: 10, pr: 10, mt: 2 }}>
        {pool?.withdrawInfo.pending ? "Pending..." : "Withdraw"}
      </Button>
    </>
  );
};

export default Withdraw;
