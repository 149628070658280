import { useState } from "react"
import { useERC1155 } from "./useContract";
import { useEthers } from "./useEthers";
import { awaitTransaction } from "../utils";

export const useERC1155Transfer = (address: string | undefined) => {
    const [txPending, setTxPending] = useState(false);
    const contract = useERC1155(address);
    const { account } = useEthers()

    const transfer = async (recipient: string, tokenId: number, quantity: number) => {
        if (!contract || !account) return;

        setTxPending(true)
        const txResponse = await awaitTransaction(contract.safeTransferFrom(account, recipient, tokenId, quantity, []));
        setTxPending(false)

        return txResponse;
    }

    return { transfer, txPending }
}