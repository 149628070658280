import { styled } from "@mui/material";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  //   alignItems: "center",
  flexDirection: "column",
  padding: theme.spacing(5),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  //   justifyContent: "flex-start",
}));

export default DrawerHeader;
