import { Backdrop, CircularProgress, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { usePoolNFT, usePoolsNFT } from "@nftvillage/farms-sdk";
import { useWallet } from "@react-dapp/wallet";
import clsx from "clsx";
import { useSelector } from "react-redux";
import fireant from "src/assets/images/fireant.png";
import { RootState } from "src/state";
import Main from "src/Widgets/Main";
import Calculator from "./components/Calculator";
import Reward from "./components/Reward";
import thunder from "src/assets/icons/thunder.png";
import { useEffect, useState } from "react";
import { metaDataApiCall } from "src/config/apiConfig";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    width: "60vw",
    [theme.breakpoints.down("lg")]: {
      marginBottom: 60,
      marginLeft: 0,
      margin: 0,
      display: "block",
      width: "100vw",
    },
  },
  note: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    width: "60vw",
    [theme.breakpoints.down("lg")]: {
      marginBottom: 60,
      marginLeft: 0,
      margin: 0,
      display: "block",
      width: "100vw",
    },
  },
  mainContainer: {
    position: "relative",
    height: "500px",
    display: "flex",
    justifyContent: "center",
    marginTop: 60,
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      height: "100%",
      marginBottom: 20,
    },
  },
  stats: {
    flex: 2,
    height: "100%",
    position: "relative",
    padding: "30px 70px 30px 40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 30,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      padding: "10px 30px",
    },
  },
  imgContainer: {
    width: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    backgroundColor: "black",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  img: {
    width: "250px",
    aspectRatio: "1/1.5",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
  thunderContainer: {
    alignSelf: "center",
    minWidth: 80,
    padding: "0px 10px",
    width: "auto",
    height: 40,
    position: "absolute",
    top: "8%",
    right: "8%",
    zIndex: 1,
    background: theme.palette.text.primary,
    textAlign: "center",
  },
  thunderIcon: {
    position: "absolute",
    left: "-32%",
    top: "-10%",
  },
}));

const NFTStaking = () => {
  const open = useSelector((state: RootState) => state.drawer.open);
  const { account } = useWallet();
  const classes = useStyles();
  const [multiplier, setMultiplier] = useState(0);

  const { pools } = usePoolsNFT();

  useEffect(() => {
    const fetchMultiplier = async () => {
      const tokenId = pools[0]?.nftDepositInfo?.multiplierCards[0]?.tokenId;
      if (tokenId) {
        const metadata = await metaDataApiCall.get(`/gameantz/${tokenId}`);
        const _multiplier = Number(
          metadata.data.attributes.find((e: any) => e.trait_type === "STAKING MULTIPLIER")?.value
        );
        setMultiplier(_multiplier / 100);
      }
    };

    if (!multiplier || pools?.length != 0) fetchMultiplier();
  }, [pools]);

  return (
    <>
      <Main open={open} className={classes.root}>
        {/* <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} >
        <CircularProgress color="inherit" />
      </Backdrop> */}
        <div className={clsx(classes.mainContainer, "mainContainer mainContainer-anim")}>
          <div className={classes.imgContainer}>
            <img src={fireant} width={350} className={classes.img} />
          </div>
          <div className={classes.stats} style={{ background: "#181728" }}>
            <div className={classes.thunderContainer}>
              <img src={thunder} alt={"Thunder Icon"} width={60} className={classes.thunderIcon} />
              <Typography variant="h4">{multiplier}X</Typography>
            </div>
            {!account ? (
              <Typography variant="h3" fontWeight={"bold"}>
                Please Connect your Wallet
              </Typography>
            ) : (
              <>
                <Calculator />
                <Reward />
              </>
            )}
          </div>
        </div>
      </Main>
      <div className={classes.note}>
        <Typography>
          1. Only one nft per wallet can be staked.
          <br />
          2. The highest multiplier nft will be picked automatically.
          <br />
          3. A minimum of 10B Antz is required to be in wallet for staking and harvesting rewards.
        </Typography>
      </div>
    </>
  );
};

export default NFTStaking;
