import { useState, useEffect } from "react";
import { useDeployERC721 } from "./useDeployContract";
import {
  postCollection,
  checkShortUrlAvailability,
  getAllCollectionsByUserAddress,
  getCollectionByShortUrl,
  getCollectionbyCollectionAddress,
  validateCollection,
  getAllCollections,
} from "../api/collection";
import { useEthers } from "@react-dapp/utils";
import { Collection, CollectionData, AssetType, ApiResponse, NotificationType } from "../config/types";
import { useNotify } from "./useNotify";
import { useConfig } from "../contexts/nftVillageSdkContext";

/**
 * Use to create the new collection
 * @returns this hook returns the following function
 * @function createCollection()
 * @param collection: CollectionData
 *
 * ```ts
 * CollectionData {
 * name?: string
 * description?: string
 * symbol?: string
 * shortUrl?: string
 * coverImage?:string
 * logoImage?:string
 * royalty?: number
 * isVerified?: boolean
 * }
 *
 * ```
 * @returns
 * - This hook returns Api response interface
 * where T is the collection interface
 *
 * ```ts
 * ApiResponse<T> {
 * status: boolean
 * message: string
 * data?: T
 * }
 *
 * ```
 */

export const useCreateCollection = () => {
  const { account } = useEthers();
  const { deploy } = useDeployERC721();
  const { notifySystem, notifyError, notifySuccess, notifyLoading, dismissNotification } = useNotify();
  const { Task } = useConfig();

  let response: ApiResponse<Collection>;

  const createCollection = async (collection: CollectionData): Promise<ApiResponse<Collection> | undefined> => {
    if (!account) {
      notifyError("Account not found !", "Cannot create collection. Please connect to your wallet.");
      return;
    }

    let notiId = notifyLoading("Please Wait...", "Creating collection...");
    Task.createCollection.start();
    try {
      let valid = await validateCollection(collection.name, collection.symbol, collection.shortUrl);
      if (!valid.status) {
        notifyError("Validation Error", valid?.message);
        dismissNotification(notiId);
        Task.createCollection.stop();
        return undefined;
      }
      let contract = await deploy(collection);

      if (contract) {
        try {
          const data: Collection = {
            ...collection,
            address: contract.address as string,
            userAddress: account,
            collectionStandard: AssetType.ERC721,
          };
          response = await postCollection(data);
        } catch (e) {
          dismissNotification(notiId);
          notifySystem("Create Collection", (e as any).response.message, NotificationType.ERROR);
          notifyError("Error", "Error while creating collection");
          Task.createCollection.stop();
          return undefined;
        }
      } else {
        dismissNotification(notiId);
        notifySystem("Error", "Contract deployement failed.", NotificationType.ERROR);
        notifyError("Error", "Error while creating collection");
        Task.createCollection.stop();
        return undefined;
      }
    } catch (e) {
      dismissNotification(notiId);
      notifySystem("Create Collection", "Oops! Something went wrong!", NotificationType.ERROR);
      notifyError("Error", "Error while creating collection");
      Task.createCollection.stop();
      return undefined;
    }
    dismissNotification(notiId);
    Task.createCollection.stop();
    notifySuccess("Success", "Collection created successfully");

    return response;
  };
  return { createCollection, loading: Task.createCollection.isRunning() };
};

/**
 * @param shortUrl
 *
 * Useage:
 *
 * use to get the collection by short url
 *
 * @returns
 * This hook returns collection interface and loading State
 *
 * @interface Collection
 *
 * ```ts
 * Collection {
 * userAddress: string
 * isVerified?: boolean
 * address: string
 * name?: string
 * description?: string
 * symbol?: string
 * shortUrl?: string
 * coverImage?:string
 * logoImage?:string
 * royalty?: number
 * collectionStandard:AssetType
 * }
 * ```
 */

export const useCollectionByShortURL = (shortUrl: string) => {
  const [collection, setCollection] = useState<Collection>();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  let response: ApiResponse<Collection>;
  useEffect(() => {
    const fetchCollection = async () => {
      Task.fetchCollectionByShortUrl.start();
      try {
        response = await getCollectionByShortUrl(shortUrl);
        if (response.data) {
          setCollection(response.data);
          notifySystem("Fetch Collection By Shorturl", response?.message, NotificationType.SUCCESS);
        }
      } catch (e) {
        notifySystem("Fetch Collection By Shorturl", (e as any).response.message, NotificationType.ERROR);
      }
      Task.fetchCollectionByShortUrl.stop();
    };
    fetchCollection();
  }, [shortUrl]);

  return { collection, loading: Task.fetchCollectionByShortUrl.isRunning() };
};

/**
 *
 * @param address
 *
 * Useage:
 *
 * use to fetch the collection from collection address
 *
 * @returns
 *
 * This hook returns collection interface and loading State
 */

export function useAllCollections <T=Collection>() {
  const [collections, setCollections] = useState<T[]>();
  const { Task } = useConfig();

  let response: ApiResponse<T[]>;

  Task.getAllCollections.start();
  const fetchAllCollections = async () => {
    try {
      response = await getAllCollections<T>();
      setCollections(response.data);
      console.log("Fetched All Collections");
    } catch (error) {
      console.log(error);
    }
    Task.getAllCollections.stop();
  };

  useEffect(() => {
    fetchAllCollections();
  }, []);

  return { collections, loading: Task.getAllCollections.isRunning() };
};

export function useCollectionByCollectionAddress<T = Collection> (address: string) {
  const [collection, setCollection] = useState<T>();
  const [loading, setLoading] = useState(false);
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  let response: ApiResponse<T>;

  useEffect(() => {
    const fetchCollectionAddress = async () => {
      try {
        Task.fetchCollectionByAddress.start();
        setLoading(true);
        response = await getCollectionbyCollectionAddress<T>(address);
        if (response.data) {
          setCollection(response.data);
          notifySystem("Fetch Collection By Address", response.message, NotificationType.SUCCESS);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        notifySystem("Fetch Collection By Address", (e as any).response.data.message, NotificationType.ERROR);
      }
      Task.fetchCollectionByAddress.stop();
    };
    fetchCollectionAddress();
  }, [address]);

  return { collection, loading };
};

/**
 *
 * @param userAddress
 *
 * Useage:
 *
 * use this hook to fetch all collection of given user address
 *
 * @returns
 *
 * This hook returns array of collection and loading State
 */

export const useAllCollectionsByUserAddress = (
  userAddress: string = "",
  defaultCollectionAddresses: Collection[] = []
) => {
  const [collection, setCollection] = useState<Collection[]>([]);
  const { account } = useEthers();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  let response: ApiResponse<Collection[]>;

  useEffect(() => {
    const fetchAllCollectionOfUser = async () => {
      try {
        if (!(userAddress || account)) {
          notifySystem("Fetch All Collections", "Account not found", NotificationType.ERROR);
          return;
        }
        Task.getAllCollectionUserAddress.start();
        response = await getAllCollectionsByUserAddress(userAddress || (account as string));
        if (response.data) {
          setCollection([...defaultCollectionAddresses, ...response.data]);
          notifySystem("Get all collection by user address", response.message, NotificationType.SUCCESS);
        }
      } catch (e) {
        notifySystem("Get all collection by user address", (e as any).response.data.message, NotificationType.ERROR);
      }
      Task.getAllCollectionUserAddress.stop();
    };
    fetchAllCollectionOfUser();
  }, [userAddress, account]);

  return { collection, loading: Task.getAllCollectionUserAddress.isRunning() };
};

/**
 *
 * @param shortUrl
 *
 * Useage:
 *
 * checks for the availability of short url for collection
 *
 * @returns
 *
 * This hook return isURLAvailable:Boolean and loading State
 */

export const useCheckShortUrlAvailability = (shortUrl: string) => {
  const [isURLAvailable, setisURLAvailable] = useState<boolean>();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  useEffect(() => {
    const _checkShortUrlAvailability = async () => {
      Task.checkShortUrlAvailability.start();
      try {
        const _isURLAvailable = await checkShortUrlAvailability(shortUrl);
        setisURLAvailable(_isURLAvailable);
        notifySystem("Short Url Availability", _isURLAvailable.toString(), NotificationType.SUCCESS);
      } catch (error) {
        notifySystem("Short Url Availability", "Error", NotificationType.ERROR);
      }
      Task.checkShortUrlAvailability.stop();
    };
    _checkShortUrlAvailability();
  }, [shortUrl]);

  return { isURLAvailable, loading: Task.checkShortUrlAvailability.isRunning() };
};
