import React from "react";
import { Button, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    gap: 20,
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15,
    marginBottom: 5,
    height: 55,
  },
}));

const Withdraw = ({ pool }: { pool: any }) => {
  const classes = useStyles();
  pool?.withdrawInfo.input.setValue("1");

  return (
    <>
      {/* <div className={classes.textField}>
        <Box sx={{ background: "#0E1c40", opacity: 0.6, display: "inline-block", flex: 2 }}>
          <TextField
            value={pool?.withdrawInfo.input.value}
            onChange={(e) => pool?.withdrawInfo.input.setValue(e.target.value)}
            variant="outlined"
            sx={{ width: "100%" }}
          />
        </Box>
        <Button
          onClick={() => pool?.withdrawInfo.input.selectMaxValue()}
          disableElevation
          variant="contained"
          sx={{ height: "100%", backgroundColor: "#0E1c40" }}
        >
          Max
        </Button>
      </div> */}
      {/* <Typography component={"small"}>Your Stack {pool?.stakedAmount}</Typography> */}
      <hr />
      <Button onClick={() => pool?.withdrawInfo.withdraw()} variant="contained" sx={{ pl: 5, pr: 5, mt: 2 }}>
        {pool?.withdrawInfo.pending ? "Pending..." : "Withdraw NFT"}
      </Button>
    </>
  );
};

export default Withdraw;
