import { relayerApi } from '../config/config'
import {
  FilterMarketPlace,
  Order,
  SearchMarketPlace,
  ApiResponse,
} from '../config/types'

export interface FilterReturn {
  orders : Order[],
  totalPages: number,
  totalOrders : number,
  page : number,
}

export const filterMarketplace = async (
  filterMarketPlace: FilterMarketPlace,
): Promise<ApiResponse<FilterReturn>> => {
  const rawResponse = await relayerApi.get(
    `search?${
      filterMarketPlace.address
        ? 'search[address]=' + filterMarketPlace.address + '&'
        : ''
    }${
      filterMarketPlace.category
        ? 'search[category]=' + filterMarketPlace.category + '&'
        : ''
    }${
      filterMarketPlace.collectionName
        ? 'search[collectionName]=' + filterMarketPlace.collectionName + '&'
        : ''
    }${
      filterMarketPlace.makerAddress
        ? 'search[makerAddress]=' + filterMarketPlace.makerAddress + '&'
        : ''
    }${
      filterMarketPlace.maxPrice
        ? 'search[maxPrice]=' + filterMarketPlace.maxPrice + '&'
        : ''
    }${
      filterMarketPlace.minPrice
        ? 'search[minPrice]=' + filterMarketPlace.minPrice + '&'
        : ''
    }${
      filterMarketPlace.name
        ? 'search[name]=' + filterMarketPlace.name + '&'
        : ''
    }${
      filterMarketPlace.type
        ? 'search[type]=' + filterMarketPlace.type + '&'
        : ''
    }${
      filterMarketPlace.sortBy
        ? 'search[sortBy]=' + filterMarketPlace.sortBy + '&'
        : ''
    }${
      filterMarketPlace.rarity
        ? 'search[rarity]=' + filterMarketPlace.rarity + '&'
        : ''
    }${
      filterMarketPlace.page
        ? 'page=' + filterMarketPlace.page + '&'
        : ''
    }`,
  )
  const response = rawResponse.data
  let ords = response.data.orders as Order[]; 
  let obj = {
    message: 'Found SuccessFully',
    status: response.status,
    data: {
      orders: ords,
      totalPages: response.data.totalPages,
      totalOrders : response.data.totalOrders,
      page : response.data.page,
    }
  }
  return  obj 
}

export const searchMarketplace = async (
  query: string,
): Promise<ApiResponse<SearchMarketPlace>> => {
  console.log(query)
  const rawResponse = await relayerApi.get(
    `search/autocomplete?search[query]=${query}`,
  )
  const response = rawResponse.data
  return {
    message: 'Found sucessfully',
    status: response.status,
    data: response.data,
  }
}
