import { Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    gap: 20,
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 5,
    marginBottom: 5,
    height: 55,
  },
}));

const Deposit = ({ pool }: { pool: any }) => {
  const classes = useStyles();
  const tokenId = useSelector((state: any) => state.user?.user?.stakeTokenId);
  return (
    <>
      {/* <div className={classes.textField}>
        <Box sx={{ background: "#0E1c40", opacity: 0.6, display: "inline-block", flex: 2 }}>
          <TextField
            value={pool?.depositInfo.input.value}
            onChange={(e) => pool?.depositInfo.input.setValue(e.target.value)}
            variant="outlined"
            sx={{ width: "100%" }}
          />
        </Box>
        <Button
          variant="contained"
          disableElevation
          onClick={() => pool?.depositInfo.input.selectMaxValue()}
          sx={{ height: "100%", backgroundColor: "#0E1c40", color: "white" }}
        >
          Max
        </Button>
      </div> */}
      {/* <Typography component={"small"}>Balance: {pool?.stakedTokenBalance} BNB - $ANTZ</Typography> */}
      <hr />
      <div
        style={{
          background: "linear-gradient(180deg, #5E4BC5 0%, #7C69E3 100%)",
          padding: "4px",
          borderRadius: "8px",
          display: "inline-block",
          marginTop: 7,
        }}
      >
        <Button
          onClick={() =>
            pool?.cardHandlerApproval.isApproved
              ? pool?.depositInfo.deposit(
                  [],
                  [],
                  [],
                  [
                    {
                      amount: 1,
                      tokenId: tokenId,
                    },
                  ]
                )
              : pool?.cardHandlerApproval.approve()
          }
          variant="contained"
          sx={{ pl: 5, pr: 5, background: "linear-gradient(180deg, #7C69E3 0%, #46398E 100%)", border: "8px" }}
        >
          {pool?.cardHandlerApproval.approvePending || pool?.depositInfo.pending
            ? "Pending..."
            : !pool?.cardHandlerApproval.isApproved
            ? "Approve NFT"
            : "Deposit NFT"}
        </Button>
      </div>
    </>
  );
};

export default Deposit;
