import { useEffect, useRef, useState } from "react";
import { useConfig } from "../contexts/configContext";
import { ethers as ethersLibrary } from "ethers";
import BigNumber from "bignumber.js";

export const useEthers = () => {
  const {
    config: { provider, rpcUrl },
  } = useConfig();
  const [ethers, setEthers] = useState<ethersLibrary.providers.Provider>(ethersLibrary.getDefaultProvider(rpcUrl));
  const [signer, setSigner] = useState<ethersLibrary.Signer>();
  const [account, setAccount] = useState<string>();
  const [balance, setBalance] = useState<BigNumber>();
  const refEth = useRef();

  const handleAccountChange = async (_ethers: any) => {
    const _signer = _ethers.getSigner();
    setSigner(_signer);
    setAccount(await _signer.getAddress());
  };

  useEffect(() => {
    const handleProviderChange = async () => {
      if (provider && provider !== refEth.current) {
        const _ethers = new ethersLibrary.providers.Web3Provider(provider);
        // @ts-ignore
        _ethers.provider.on("accountsChanged", () => handleAccountChange(_ethers));
        setEthers(_ethers);
        handleAccountChange(_ethers);
      } else {
        // setEthers(undefined)
        setSigner(undefined);
        setAccount(undefined);
      }
      refEth.current = provider;
    };
    handleProviderChange();
  }, [provider]);

  useEffect(() => {
    const fetch = async () => {
      if (account && ethers) {
        const bal = await ethers.getBalance(account);
        setBalance(new BigNumber(bal.toString()));
      } else {
        setBalance(undefined);
      }
    };
    fetch();
  }, [ethers, account]);

  return {
    ethers,
    signer,
    account,
    balance,
    displayAccount: account && `${account.substring(0, 4)}...${account.substring(account.length - 4, account.length)}`,
    connected: account ? true : false,
  };
};
