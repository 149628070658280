import { NotificationType } from "../config/types";
import { useConfig } from "../contexts/nftVillageSdkContext";

export const useNotify = () => {
  const {
    config: {
      notifySystem,
      notifyError,
      notifyLoading,
      notifySuccess,
      dismissNotification,
      dissmissAllNotifications,
      notifyWarning,
      notifyWarningWithInput,
    },
  } = useConfig();
 
  return {
    notifySystem,
    notifyError,
    notifyLoading,
    notifySuccess,
    dismissNotification,
    dissmissAllNotifications,
    notifyWarning,
    notifyWarningWithInput,
  };
};
