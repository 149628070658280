import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { toLowerUnit } from "../utils";
import { useERC20, useERC1155, useERC721 } from "./useContract";
import { useRefresh } from "./useRefresh";
import { useEthers } from "./useEthers";
import { toBigNumber } from "./../utils";
import { TokenBalance } from "../config/types";

export const useERC20Balance = (address: string, tokenDecimals: number = 18, displayDecimals: number = 0) => {
    const [balance, setBalance] = useState<BigNumber>();
    const [displayBalance, setDisplayBalance] = useState<string>();
    const { fastRefresh } = useRefresh();

    const contract = useERC20(address);
    const { account } = useEthers();

    useEffect(() => {
        const fetch = async () => {
            if (contract && account) {
                const bal = (await contract.balanceOf(account)) as ethers.BigNumber;
                const _balance = toBigNumber(bal);
                setBalance(_balance);
                setDisplayBalance(toLowerUnit(_balance.toFixed(0), tokenDecimals).toFormat(displayDecimals));
            }
        };
        fetch();
    }, [contract, account, fastRefresh]);

    return { balance, displayBalance };
};

export const useERC721Balance = (address: string, tokenId: number) => {
    const [balance, setBalance] = useState<TokenBalance>();
    const [loading, setLoading] = useState(false);

    const contract = useERC721(address);
    const { account } = useEthers();

    useEffect(() => {
        const fetch = async () => {
            if (contract && account) {
                setLoading(true);
                try {
                    const bal = await contract.balanceOf(account, tokenId);
                    const balances = {
                        tokenId: tokenId,
                        amount: toBigNumber(bal).toNumber(),
                        account: account,
                    };
                    setBalance(balances);
                } catch (e) {
                    console.log(e);
                }
                setLoading(false);
            }
        };
        fetch();
    }, [contract, account]);

    return { balance, loading };
};

export const useERC1155Balance = (erc1155Address: string, tokenIds: number[], userAddress?: string) => {
    const [balance, setBalance] = useState<TokenBalance[]>();
    const [loading, setLoading] = useState(false);

    const contract = useERC1155(erc1155Address);
    const { account } = useEthers();

    useEffect(() => {
        const fetch = async () => {
            if (contract && (account || userAddress)) {
                setLoading(true);
                try {
                    const _userAddress = userAddress ?? (account as string);
                    const accounts = tokenIds.map(() => _userAddress);
                    const bal = await contract.balanceOfBatch(accounts, tokenIds);
                    const balances = tokenIds.map((e, i) => {
                        return {
                            tokenId: e,
                            amount: toBigNumber(bal[i]).toNumber(),
                            account: _userAddress,
                        };
                    });
                    setBalance(balances);
                } catch (e) {
                    console.log(e);
                }
                setLoading(false);
            }
        };
        fetch();
    }, [contract, account]);

    return { balance, loading };
};
