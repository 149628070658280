import React from "react";
import { makeStyles } from "@mui/styles";
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import AddressTypography from "src/components/AddressTypography/AddressTypography";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { getLeaderboard } from "src/api/leaderboard";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  tbody: {
    "& tr:nth-child(1)": {
      backgroundColor: "rgba(124, 105, 227,1)",
      mixBlendMode: "screen",
    },
    "& tr:nth-child(2)": {
      backgroundColor: "rgba(146, 128, 243, 1)",
      mixBlendMode: "screen",
    },
    "& tr:nth-child(3)": {
      backgroundColor: "rgba(183, 170, 255, 1)",
      mixBlendMode: "screen",
    },
  },
}));

interface IProps {}

const LeaderboardTable: React.FC<IProps> = () => {
  const classes = useStyles();
  const { isLoading, data } = useQuery(["leaderboard"], getLeaderboard);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head">#RANK</TableCell>
            <TableCell variant="head">ACCOUNTS</TableCell>
            <TableCell variant="head" align="center">
              AMOUNT OF ANTZ
            </TableCell>
            <TableCell variant="head" align="center">
              LAST BUY
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tbody}>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={4}>
                <LinearProgress sx={{ mt: 2 }} />
              </TableCell>
            </TableRow>
          )}
          {data?.map((item, i) => (
            <TableRow key={i}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>
                <AddressTypography address={item.id} />
              </TableCell>
              <TableCell align="center">{item.value}</TableCell>
              <TableCell align="center">
                <Typography align="center">{moment(Number(item.lastBuy) * 1000).format("DD-MMM-YYYY")}</Typography>
              </TableCell>
            </TableRow>
          ))}
          {(!data || data.length === 0) && !isLoading && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                There is currently no competition ongoing check back soon...
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LeaderboardTable;
