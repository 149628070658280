import { useMemo } from "react";
import { useEthers } from "./useEthers";
import ERC20_ABI from "../assets/abi/erc20Abi.json";
import ERC165_ABI from "../assets/abi/erc165Abi.json";
import ERC721_ABI from "../assets/abi/erc721Abi.json";
import ERC1155_ABI from "../assets/abi/erc1155Abi.json";
import { Contract, Signer, providers, utils, ContractInterface, BaseContract } from "ethers";

const getContract = (
    abi: ContractInterface,
    address: string | undefined,
    ethers: providers.Provider | undefined,
    signer: Signer | undefined
) => {
    if (ethers && address) {
        return new Contract(utils.getAddress(address), abi, signer ?? ethers);
    }
};

export const useContract: <T>(abi: ContractInterface, address: string | undefined) => T | Contract | undefined = (
    abi,
    address
) => {
    const { ethers, signer } = useEthers();
    return useMemo(() => getContract(abi, address, ethers, signer), [ethers, signer, abi, address]);
};

export const useERC165 = (address: string | undefined) => {
    const { ethers, signer } = useEthers();
    return useMemo(() => getContract(ERC165_ABI, address, ethers, signer), [ethers, signer, address]);
};

export const useERC721 = (address: string | undefined) => {
    const { ethers, signer } = useEthers();
    return useMemo(() => getContract(ERC721_ABI, address, ethers, signer), [ethers, signer, address]);
};

export const useERC1155 = (address: string | undefined) => {
    const { ethers, signer } = useEthers();
    return useMemo(() => getContract(ERC1155_ABI, address, ethers, signer), [ethers, signer, address]);
};

export const useERC20 = (address: string | undefined) => {
    const { ethers, signer } = useEthers();
    return useMemo(() => getContract(ERC20_ABI, address, ethers, signer), [ethers, signer, address]);
};
