import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Theme,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  LinearProgress,
  Typography,
  Container,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getLeaderboard } from "src/api/leaderboard";
import moment from "moment";
import AddressTypography from "src/components/AddressTypography/AddressTypography";
import { useTimer } from "src/hooks/useTimer";
import EndingCountdown from "./components/EndingCountdown";
import LeaderboardTable from "./components/LeaderboardTable";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: "90px",
    marginLeft: "260px",
    [theme.breakpoints.down("md")]: {
      margin: "90px 0px",
    },
  },

  timerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
}));

interface IProps {}

const Leaderboard: React.FC<IProps> = () => {
  const classes = useStyles();
  const { timeFinished, timeLeft } = useTimer(new Date("9 Sep 2022 13:00 UTC").getTime() / 1000);
  const { timeFinished: endingTimeFinished, timeLeft: endingTimeLeft } = useTimer(
    new Date("17 Sep 2022 13:00 UTC").getTime() / 1000
  );

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        {timeFinished ? (
          <>
            <Typography align="center" variant="h4" sx={{ mb: 3 }}>
              {endingTimeFinished ? "Battle finished" : "Battle will end in"}
            </Typography>
            {!endingTimeFinished && <EndingCountdown timeLeft={endingTimeLeft} />}
            <LeaderboardTable />
          </>
        ) : (
          <div>
            <Typography variant="h4" sx={{ mb: 3 }} align="center">
              Countdown to battle...
            </Typography>
            <div className={classes.timerWrapper}>
              <div>
                <Typography align="center" variant="h4" fontWeight={300}>
                  {timeLeft.days}
                </Typography>
                <Typography align="center" variant="h5" fontWeight={300}>
                  Days
                </Typography>
              </div>
              <div>
                <Typography align="center" variant="h4" fontWeight={300}>
                  {timeLeft.hours}
                </Typography>
                <Typography align="center" variant="h5" fontWeight={300}>
                  Hours
                </Typography>
              </div>
              <div>
                <Typography align="center" variant="h4" fontWeight={300}>
                  {timeLeft.minutes}
                </Typography>
                <Typography align="center" variant="h5" fontWeight={300}>
                  Mins
                </Typography>
              </div>
              <div>
                <Typography align="center" variant="h4" fontWeight={300}>
                  {timeLeft.seconds}
                </Typography>
                <Typography align="center" variant="h5" fontWeight={300}>
                  Secs
                </Typography>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Leaderboard;
