import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme, Typography } from "@mui/material";
import { useTimer } from "src/hooks/useTimer";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  timerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
}));

interface IProps {
    timeLeft: any
}

const EndingCountdown: React.FC<IProps> = ({timeLeft}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.timerWrapper}>
        <div>
          <Typography align="center" variant="h4" fontWeight={300}>
            {timeLeft.days}
          </Typography>
          <Typography align="center" variant="h5" fontWeight={300}>
            Days
          </Typography>
        </div>
        <div>
          <Typography align="center" variant="h4" fontWeight={300}>
            {timeLeft.hours}
          </Typography>
          <Typography align="center" variant="h5" fontWeight={300}>
            Hours
          </Typography>
        </div>
        <div>
          <Typography align="center" variant="h4" fontWeight={300}>
            {timeLeft.minutes}
          </Typography>
          <Typography align="center" variant="h5" fontWeight={300}>
            Mins
          </Typography>
        </div>
        <div>
          <Typography align="center" variant="h4" fontWeight={300}>
            {timeLeft.seconds}
          </Typography>
          <Typography align="center" variant="h5" fontWeight={300}>
            Secs
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default EndingCountdown;
