import { useState, useEffect } from "react";
import { filterMarketplace, searchMarketplace } from "../api/search";
import { FilterMarketPlace, SearchMarketPlace, ApiResponse, Order, NotificationType } from "../config/types";
import { useConfig } from "../contexts/nftVillageSdkContext";
import { useNotify } from "./useNotify";

/**
 * use to filterMarketPlace orders
 * @returns this hook returns the following function
 * @function filterMarketPlace
 * @param _filterMarketplace: FilterMarketPlace
 *
 * ```ts
 * FilterMarketPlace {
 * address?: string
 * makerAddress?: string
 * name?: string
 * collectionName?: string
 * category?: string
 * minPrice?: string
 * maxPrice?: string
 * sortBy?: SortBy
 * }
 * ```
 *
 * @returns
 *
 * This hook returns Api response interface
 * where T is the array of Order interface
 *
 * ```ts
 * ApiResponse<T> {
 * status: boolean
 * message: string
 * data?: T
 * }
 * ```
 */
export const useFilterMarketPlace = (initialState: FilterMarketPlace | undefined) => {
  const { notifySystem } = useNotify();
  const [page, setPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [filterState, setFilterState] = useState<FilterMarketPlace>(initialState || {});

  const resetFilter = () => {
    setFilterState(initialState || {});
  };

  useEffect(() => {
    filterMarketPlace();
  }, [filterState]);

  async function filterMarketPlace() {
    try {
      setLoading(true);
      let response = await filterMarketplace(filterState);
      if (response.data) {
        notifySystem("Filter Marketplace", response.message, NotificationType.SUCCESS);
      }
      setPage(response.data?.page || 1);
      setTotalOrders(response.data?.totalOrders || 1);
      setTotalPages(response.data?.totalPages || 1);
      setOrders(response.data?.orders || []);
      setLoading(false);
      return response;
    } catch (e) {
      setLoading(false);
      notifySystem("Filter Marketplace", (e as any)?.response?.data.message, NotificationType.ERROR);
      console.log(e);
      return null;
    }
  }

  return {
    filterMarketPlace,
    page,
    totalOrders,
    totalPages,
    setFilterState,
    filterState,
    orders,
    loading,
    resetFilter,
  };
};

/**
 *
 * @param query:string
 *
 * Useage:
 *
 * use to search marketplace for collection and for accounts
 *
 * @returns this hook returns SearchMarketPlace interface
 * @interface SearchMarketPlace
 *
 * ```ts
 * {
 * names: string[]
 * collectionNames: string[]
 * }
 * ```
 */
export const useSearchMarketPlace = (query: string) => {
  const [search, setSearch] = useState<SearchMarketPlace>();
  const { notifySystem } = useNotify();
  const { Task } = useConfig();

  let response: ApiResponse<SearchMarketPlace>;

  useEffect(() => {
    const searchMarketPlace = async () => {
      Task.searchMarketplace.start();
      try {
        response = await searchMarketplace(query);
        if (response.data) {
          setSearch(response.data);
          notifySystem("Use Search Marketplace", response.message, NotificationType.SUCCESS);
        }
      } catch (e) {
        notifySystem("Use Search Marketplace", (e as any).response.data.message, NotificationType.ERROR);
      }
      Task.searchMarketplace.stop();
    };
    searchMarketPlace();
  }, [query]);
  return { search, loading: Task.searchMarketplace.isRunning() };
};
