import BigNumber from "bignumber.js/bignumber";
import axios from "axios";
import { MarketplaceSdkConfig } from "./types";

// Big Number
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

// Ethers Provider
const defaultConfig: MarketplaceSdkConfig = {
  supportedERC20Tokens: [],
  dismissNotification: (id) => {},
  dissmissAllNotifications: () => {},
  notifySystem: (title, message, type) => {},
  notifySuccess: (title, message) => {},
  notifyError: (title, message) => {},
  notifyLoading: (title, message) => {
    return "";
  },
  notifyWarning: (title, message) => {},
  notifyWarningWithInput: (title, message) => {
    return false;
  },
};

// Contracts
export const EXCHANGE_ADDRESS = process.env.REACT_APP_EXCHANGE_ADDRESS as string;
export const NATIVE_ERC20_ADDRESS = process.env.REACT_APP_NATIVE_ERC20_ADDRESS as string;
export const NATIVE_ERC721_ADDRESS = process.env.REACT_APP_NATIVE_ERC721_ADDRESS as string;
export const NATIVE_ERC1155_ADDRESS = process.env.REACT_APP_NATIVE_ERC1155_ADDRESS as string;

export const ZERO_ADDRESS = process.env.REACT_APP_ZERO_ADDRESS as string;
export const RELAYER_FEE = process.env.REACT_APP_RELAYER_FEE as string;
export const PROTOCOL_FEE = process.env.REACT_APP_PROTOCOL_FEE as string;
export const RELAYER_ADDRESS = process.env.REACT_APP_RELAYER_ADDRESS as string;
export const PROTOCOL_ADDRESS = process.env.REACT_APP_PROTOCOL_ADDRESS as string;

// Api
export const BASE_RELAYER_URL = process.env.REACT_APP_BASE_RELAYER_URL as string;

export const relayerApi = axios.create({
  baseURL: BASE_RELAYER_URL,
  timeout: 100000,
});

export default defaultConfig;
