import { useEffect, useState } from "react";
import { fetchIpfs } from "../utils/index";
import { useNft } from "./useNft";
import { TokenStandard } from "../config/types";
import { useERC1155 } from "./useContract";

/**
 * This hook fetches the metadata of an ERC1155 token.
 *
 * @param contractAddress Address of contract
 *
 */
export const useMetadata = (contractAddress: string | undefined, tokenId: string | undefined) => {
    const [loading, setLoading] = useState(false);
    const [metadata, setMetadata] = useState<any>();
    const { contract, tokenStandard } = useNft(contractAddress);

    useEffect(() => {
        const fetchMetadata = async () => {
            if (!contract || !tokenStandard || !contractAddress || !tokenId) return;
            try {
                setLoading(true);
                let uri: string =
                    tokenStandard === TokenStandard.ERC721
                        ? await contract.tokenURI(tokenId)
                        : tokenStandard === TokenStandard.ERC1155
                        ? await contract.uri(tokenId)
                        : undefined;
                if (uri) {
                    uri = uri.replaceAll("{address}", contractAddress);
                    uri = uri.replaceAll("{id}", tokenId);
                    let data;
                    if (uri.includes("ipfs://")) {
                        data = await fetchIpfs(uri);
                        setMetadata(data);
                    } else {
                        data = await (await fetch(uri)).json();
                        if (data?.image?.includes("ipfs://")) {
                            data.image = `https://ipfs.io/${data.image.replace("ipfs://", "")}`;
                        }
                        setMetadata(data);
                    }
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error);
                throw "Unable to fetch Metadata";
            }
        };

        fetchMetadata();
    }, [contractAddress, contract, tokenId, tokenStandard]);

    return { metadata, loading };
};

export const useFetchMetadataForTokenIdsERC1155 = (erc1155Address: string, addressUri = "") => {
    let contract = useERC1155(erc1155Address);

    const fetchMetadta = (tokenId: number) =>
        new Promise(async (resolve, _) => {
            try {
                if (!contract) return;
                let uri: string = addressUri || (await contract.uri(tokenId));
                uri = uri.replaceAll("{address}", erc1155Address);
                uri = uri.replaceAll("{id}", tokenId.toString());
                let data;
                if (uri.includes("ipfs://")) {
                    data = await fetchIpfs(uri);
                } else {
                    let response = await fetch(uri);
                    data = await response.json();
                }
                resolve({ ...data, address: erc1155Address });
            } catch (error) {
                resolve({ name: "", image: "" });
            }
        });

    const fetchAllMetadata = async (tokenIds: number[]) => {
        let allData = [];
        let promises: Promise<unknown>[] = [];
        tokenIds.forEach((tokenId, i) => {
            let promise = fetchMetadta(tokenId);
            promises[i] = promise;
        });
        allData = await Promise.all(promises);
        return allData;
    };

    return { fetchAllMetadata };
};
