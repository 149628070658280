import { Backdrop, CircularProgress, Theme, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/state";
import Main from "src/Widgets/Main";
import { makeStyles } from "@mui/styles";
import Calculator from "./components/Calculator";
import Reward from "./components/Reward";
import { usePools } from "@nftvillage/farms-sdk";
import { useWallet } from "@react-dapp/wallet";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
    alignItems: "center",
    gap: 50,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

const Staking = () => {
  const open = useSelector((state: RootState) => state.drawer.open);
  const { account } = useWallet();
  const classes = useStyles();
  const { loading } = usePools();

  return (
    <Main open={open} className={classes.root}>
      {/* <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {!account ? (
        <Typography variant="h2" fontWeight={"bold"}>
          Please Connect your Wallet
        </Typography>
      ) : (
        <>
          <Calculator />
          <Reward />
        </>
      )}
    </Main>
  );
};

export default Staking;
