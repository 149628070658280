import React from "react";
import { IconButton, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import discord from "src/assets/icons/discord.png";
import youtube from "src/assets/icons/youtube.png";
import twitter from "src/assets/icons/twitter.png";
import tiktok from "src/assets/icons/tiktok.png";
import telegram from "src/assets/icons/telegram.png";
import Main from "src/Widgets/Main";
import { useSelector } from "react-redux";
import { RootState } from "src/state";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 100,
    margin: "0 auto",
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

interface Props {}

const Footer: React.FC<Props> = () => {
  const open = useSelector((state: RootState) => state.drawer.open);
  const classes = useStyles();

  return (
    <Main open={open} className={classes.root}>
      <IconButton sx={{ m: 1 }} onClick={() => window.open("https://discord.gg/GameAntz", "__blank")}>
        <img src={discord} />
      </IconButton>
      <IconButton sx={{ m: 1 }} onClick={() => window.open("https://twitter.com/GameAntz", "__blank")}>
        <img src={twitter} />
      </IconButton>
      <IconButton sx={{ m: 1 }} onClick={() => window.open("http://tiktok.com/@gameantz", "__blank")}>
        <img src={tiktok} />
      </IconButton>
      <IconButton sx={{ m: 1 }} onClick={() => window.open("https://t.me/GameAntzOfficiaL", "__blank")}>
        <img src={telegram} />
      </IconButton>
    </Main>
  );
};

export default Footer;
