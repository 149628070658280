import React from "react";
import { IconButton, Toolbar } from "@mui/material";
import WalletIcon from "../WalletIcon/WalletIcon";
import AppBar from "src/Widgets/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state";
import { setOpen } from "src/state/drawer/drawerReducer";

interface Props {}

const Navbar: React.FC<Props> = () => {
  const open = useSelector((state: RootState) => state.drawer.open);
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    dispatch(setOpen(true));
  };

  return (
    <AppBar position="fixed" elevation={0} open={open} sx={{ background: "transparent" }}>
      <Toolbar sx={{ width: { xs: "100%", lg: "100%", xl: "100%" } }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <WalletIcon />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
