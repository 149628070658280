import BigNumber from "bignumber.js";

export const toLowerUnit = (value: string, decimals: number = 18): BigNumber => {
    let ans = new BigNumber("0");
    if (value) ans = new BigNumber(value.toString()).div(new BigNumber(10).exponentiatedBy(decimals));
    return ans;
};

export const toUpperUnit = (value: string, decimals: number = 18): BigNumber => {
    let ans = new BigNumber("0");
    if (value) {
        ans = new BigNumber(value.toString()).times(new BigNumber(10).exponentiatedBy(decimals));
    }
    return ans;
};

export const getTimeLeft = (
    delta: number
): { days: number; hours: number; minutes: number; seconds: number } | undefined => {
    if (delta <= 0) return undefined;

    // calculate (and subtract) whole days
    let days = Math.floor(delta / 86400);
    delta -= days * 86400;
    days = parseInt(days.toString());

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    hours = parseInt(hours.toString());

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    minutes = parseInt(minutes.toString());

    // what's left is seconds
    var seconds = delta % 60;
    seconds = parseInt(seconds.toString());

    return { days, hours, minutes, seconds };
};

export const bigNumberObjtoString = (obj: any) => {
    let transformedObj: any;

    if (Array.isArray(obj)) {
        transformedObj = obj.map((e) => {
            return bigNumberObjtoString(e);
        });
        return transformedObj;
    } else if (obj && typeof obj === "object") {
        transformedObj = {};
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const element = obj[key];
                if (BigNumber.isBigNumber(element)) transformedObj[key] = element.toFixed(0);
                else if (typeof element === "object") transformedObj[key] = bigNumberObjtoString(element);
                else transformedObj[key] = element;
            }
        }
    }
    return transformedObj ?? obj;
};

/**
 * To convert ethers bignumber to bignumber.js
 * @param value is the BigNumber from ethers.js
 * @returns BigNumber of bignumber.js
 */
export const toBigNumber = (value: any): BigNumber => {
    if (!value) return new BigNumber(0);
    if (value._isBigNumber) return new BigNumber(value?.toHexString ? value.toHexString() : value);
    if (value.type === "BigNumber") return new BigNumber(value.hex);
    return new BigNumber(String(value));
};

export const getApy = (
    stakingTokenPrice: string,
    rewardTokenPrice: string,
    totalStaked: string,
    tokenPerBlock: number,
    blocksPerYear: BigNumber = new BigNumber(10512000)
): number | null => {
    const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(blocksPerYear);
    const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked);
    const apy = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);
    return apy.isNaN() || !apy.isFinite() ? null : apy.toNumber();
};

export const awaitTransaction = async (transaction: any) => {
    let tx;
    let receipt;
    let error;
    let status;
    try {
        tx = await transaction;
        receipt = await tx.wait();
        status = true;
    } catch (e: any) {
        if (e.code === 4001) error = "Transaction Denied!";
        else if (e.code === -32000) error = "Insuficient Funds in your account for transaction";
        else if (e.data?.code === -32000) error = "Insuficient Funds in your account for transaction";
        else if (e.data?.message) error = e.data.message;
        else if (e.message) error = e.message;
        status = false;
    }
    return {
        tx,
        receipt,
        error,
        status,
    };
};

/**
 * Fetch IPFS data
 *
 * @param ipfsUrl IPFS url of the metadata
 * @returns json object of the metadata
 */
export const fetchIpfs = (ipfsUrl: string) =>
    new Promise(async (resolve) => {
        let url = `https://ipfs.io/${ipfsUrl.replace("ipfs://", "")}`;
        let response = await fetch(url);
        const data = await response.json();
        if (data?.image?.includes("ipfs://")) {
            data.image = `https://ipfs.io/${data.image.replace("ipfs://", "")}`;
        }
        resolve(data);
    });
