import { toLowerUnit, toUpperUnit, useContract } from "@react-dapp/utils";
import { ethers } from "ethers";
import React, { useEffect } from "react";
import { NFT_SALE_ADDRESS } from "src/config/config";
import useNotify from "./useNotify";
import { nft_sale_abi } from "src/contracts/abi/NFTSale";
import { useWallet } from "@react-dapp/wallet";

export const useNFTContract = () => {
  const [loading, setLoading] = React.useState(false);
  const [isMinted, setIsMinted] = React.useState(false);
  const [price, setPrice] = React.useState<ethers.BigNumber>();
  const [displayPrice, setDisplayPrice] = React.useState<string>("0.16");

  const { notifyError, notifySuccess } = useNotify();
  const nftSale = useContract<any>(nft_sale_abi, NFT_SALE_ADDRESS);

  useEffect(() => {
    const fetchPrice = async () => {
      if (!nftSale?.signer) return;

      const _price = await nftSale.price();
      setPrice(_price);
      setDisplayPrice(toLowerUnit(_price).toString());
    };

    fetchPrice();
  }, [nftSale]);

  async function mintNFT(quantity: any) {
    if (!nftSale) return;

    try {
      setIsMinted(false);
      setLoading(true);

      let amount = ethers.BigNumber.from(quantity);

      const finalPrice = amount.mul(price ?? "0");

      const tx = await nftSale.mint(quantity, {
        value: finalPrice,
        gasLimit: Number(quantity) * 250000,
      });

      await tx.wait();

      setIsMinted(true);

      setTimeout(() => {
        setIsMinted(false);
      }, 9000);

      console.log("Transaction", tx);
    } catch (error) {
      console.log("Error", error);
      notifyError("Error", "Error minting NFT");
    } finally {
      setLoading(false);
    }
  }

  return { mintNFT, loading, isMinted, displayPrice };
};
