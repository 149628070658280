import { Tab, Tabs, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo2 from "src/assets/logos/logo2.png";
import binance from "src/assets/logos/binance.png";
import React from "react";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import { TOKEN_STAKING_POOL_IDS } from "src/config/config";
import { usePool } from "@nftvillage/farms-sdk";
import useNotify from "src/hooks/useNotify";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    gap: 50,
  },
  logos: {
    padding: "5px 0px",
    display: "flex",
    gap: 140,
    borderBottom: "1px solid white",
    [theme.breakpoints.down("md")]: {
      gap: 40,
    },
  },
  liquidity: {
    height: "100%",
  },
  stats: {
    display: "flex",
    gap: 70,
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      gap: 40,
    },
  },
  deposit: {
    marginTop: 30,
  },
  labels: {
    display: "flex",
    marginBottom: 10,
    justifyContent: "space-between",
    "& .MuiTabs-scroller": {
      borderBottom: "1px solid white",
    },
    "& .MuiTabs-indicator": {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
  },
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Calculator = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { notifyError } = useNotify();

  const pool = usePool(TOKEN_STAKING_POOL_IDS[0], (e) => {
    if (e.indexOf("cannot estimate gas") === 0) notifyError("Error", "Deposit amount too high!");
    else notifyError("Error", e);
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.liquidity}>
      <div className={classes.logos}>
        <div>
          <img src={logo2} width="120" />
          <Typography paragraph align="center" mt={1}>
            ANTZ
          </Typography>
        </div>
        <div>
          <img src={binance} width="100" />
          <Typography paragraph align="center" mt={1}>
            BNB
          </Typography>
        </div>
      </div>
      <div className={classes.stats}>
        <Typography component={"div"} variant="h3">
          Liquidity
          <Typography color={"text.primary"} variant="h3" fontFamily={"'Oswald'"}>
            ${pool?.details.stats?.liquidity?.toFormat(0) ?? "0.00"}
          </Typography>
        </Typography>
        <Typography component={"div"} variant="h4">
          APY
          <Typography color={"text.primary"} variant="h4" fontFamily={"'Oswald'"}>
            {pool?.rewards[0].apy ?? 0.0} %
          </Typography>
        </Typography>
      </div>
      <div className={classes.deposit}>
        <Tabs
          value={value}
          variant="fullWidth"
          indicatorColor="primary"
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.labels}
        >
          <Tab label="Deposit" {...a11yProps(0)} sx={{ color: "white", textTransform: "none" }} />
          <Tab label="Withdraw" {...a11yProps(1)} sx={{ color: "white", textTransform: "none" }} />
        </Tabs>
        {/* </div> */}
        {value === 0 && <Deposit pool={pool} />}
        {value === 1 && <Withdraw pool={pool} />}
      </div>
    </div>
  );
};

export default Calculator;
