import { useState, useContext, createContext, useEffect } from 'react'
import { BSC_DEFAULT_CONFIG } from '../config/config'
import { UtilsConfig } from '../config/types';

const ConfigContext = createContext<{ config: UtilsConfig, setConfig: (config: UtilsConfig) => void }>({
    config: BSC_DEFAULT_CONFIG, setConfig: () => null
});

const ConfigContextProvider = ({ children, config }: { children: JSX.Element, config: UtilsConfig }) => {
    const [_config, _setConfig] = useState({
        ...BSC_DEFAULT_CONFIG,
        ...config,
    })

    useEffect(() => {
        _setConfig(config)
    }, [config])

    return <ConfigContext.Provider value={{ config: _config, setConfig: _setConfig }}>{children}</ConfigContext.Provider>
}

export { ConfigContext, ConfigContextProvider };

export const useConfig = () => {
    return useContext(ConfigContext);
}
