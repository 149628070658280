import { Button, Container, Grid, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import nft3 from "src/assets/images/nft3.png";
import thunder from "src/assets/icons/thunder.png";
import theme from "src/utils/theme";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state";
import { fetchMetadataWithId } from "src/state/user/userReducer";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: "90px",
    marginLeft: "260px",
    [theme.breakpoints.down("md")]: {
      margin: "90px 0px",
    },
  },
  con: {
    position: "relative",
    background: "#181728",
    border: "2px solid #7C69E3",
    width: "95%",
  },
  crossCon: {
    position: "absolute",
    top: "0%",
    right: "0%",
  },
  btn: {
    height: "37px",
    color: "black",
    fontWeight: 700,
    background: "#E6E6E6",
    padding: "0px",
    borderRadius: "0px",
  },
  detailsContainer: {
    padding: 20,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nftImage: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    border: "2px solid white",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  thunderContainer: {
    alignSelf: "center",
    width: 85,
    height: 40,
    position: "relative",
    background: theme.palette.text.primary,
    textAlign: "center",
  },
  thunderIcon: {
    position: "absolute",
    left: "-35%",
    top: "-10%",
  },
  attributes: {
    marginTop: 20,
    display: "flex",
    flexWrap: "wrap",
    gap: 15,
  },
}));

interface IAttribute {
  type: string;
  value: string | number;
}

const Attribute: React.FC<IAttribute> = ({ type, value }) => {
  const styles = {
    minWidth: "120px",
    borderRadius: "3px",
    padding: "10px 10px",
    border: `2px solid ${theme.palette.text.primary}`,
    background: "transparent",
  };

  return (
    <div style={styles}>
      <Typography variant="body2">{type}</Typography>
      <Typography variant="body2" fontWeight={700}>
        {value}
      </Typography>
    </div>
  );
};

interface UserNFTDetailProps {}

export const UserNFTDetail: React.FC<UserNFTDetailProps> = ({}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const tokenId = useParams<{ tokenId: string }>();
  const dispatch = useDispatch();
  const { metadataWithId } = useSelector((state: RootState) => state.user.user);
  const [multiplier, setMultiplier] = useState(0);

  React.useEffect(() => {
    dispatch(fetchMetadataWithId({ tokenId: tokenId as string }));
  }, []);

  React.useEffect(() => {
    const getMultiplier = () => {
      const prop = metadataWithId.attributes.find((e) => e.trait_type === "STAKING MULTIPLIER");
      console.log(prop);
      setMultiplier(Number(prop?.value) / 100);
    };
    getMultiplier();
  }, [metadataWithId]);

  console.log("Metadata", metadataWithId);

  const attributes = [
    {
      type: "Damage",
      value: 6,
    },
    {
      type: "Health",
      value: 60,
    },
    {
      type: "Generation",
      value: 6,
    },
    {
      type: "Staking Multiplier",
      value: 110,
    },
    {
      type: "Magic Power",
      value: 6,
    },
    {
      type: "Feromone Power",
      value: 4,
    },
    {
      type: "Attock Power",
      value: 6,
    },
    {
      type: "Healing Power",
      value: 6,
    },
    {
      type: "Weapon",
      value: "Axe of Cruelty",
    },
    {
      type: "Damage",
      value: 6,
    },
    {
      type: "Health",
      value: 60,
    },
    {
      type: "Generation",
      value: 6,
    },
    {
      type: "Staking Multiplier",
      value: 110,
    },
    {
      type: "Magic Power",
      value: 6,
    },
    {
      type: "Feromone Power",
      value: 4,
    },
    {
      type: "Attock Power",
      value: 6,
    },
    {
      type: "Healing Power",
      value: 6,
    },
    {
      type: "Weapon",
      value: "Axe of Cruelty",
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.con}>
        <div className={classes.crossCon}>
          <Button className={classes.btn} onClick={() => navigate("/account")}>
            X
          </Button>
        </div>
        <div className={classes.detailsContainer}>
          <Grid container justifyContent={"center"} gap={4}>
            <Grid item lg={3.5}>
              <img src={metadataWithId.image} className={classes.nftImage} />
            </Grid>
            <Grid item lg={7.5}>
              <div className={classes.nameContainer}>
                <div>
                  <Typography variant={"h2"} color="text.primary" fontWeight={700} fontFamily={"'Oswald'"}>
                    {metadataWithId.name}
                  </Typography>
                  <Typography>{metadataWithId.description}</Typography>
                </div>
                <div className={classes.thunderContainer}>
                  <img src={thunder} alt={"Thunder Icon"} width={60} className={classes.thunderIcon} />
                  <Typography variant="h4">{multiplier}X</Typography>
                </div>
              </div>
              <div className={classes.attributes}>
                {metadataWithId.attributes.map((attr) => (
                  <Attribute type={attr.trait_type} value={attr.value} />
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
