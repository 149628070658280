import { Contract } from "ethers";
import { useEffect, useState } from "react";
import { useEthers } from "../hooks/useEthers"
import { useERC165 } from "../hooks/useContract"
import { TokenStandard } from "../config/types";
import { ERC721_INTERFACE, ERC1155_INTERFACE } from "../config/config";
import ERC721_ABI from "../assets/abi/erc721Abi.json";
import ERC1155_ABI from "../assets/abi/erc1155Abi.json";

export const useNft = (address: string | undefined) => {
  const [contract, setContract] = useState<Contract>();
  const [tokenStandard, setTokenStandard] = useState<TokenStandard>();
  const { signer } = useEthers();
  const erc165 = useERC165(address);

  useEffect(() => {
    const checkInterface = async () => {
      if (!signer || !erc165 || !address) return;

      const isErc721 = await erc165.supportsInterface(ERC721_INTERFACE);
      if (isErc721) {
        const _erc721 = new Contract(address, ERC721_ABI, signer);
        setContract(_erc721);
        setTokenStandard(TokenStandard.ERC721);
      } else {
        const isErc1155 = await erc165.supportsInterface(ERC1155_INTERFACE);
        if (isErc1155) {
          const _erc1155 = new Contract(address, ERC1155_ABI, signer);
          setContract(_erc1155);
          setTokenStandard(TokenStandard.ERC1155);
        }
      }
    };
    checkInterface();
  }, [erc165]);

  return { contract, tokenStandard };
};
