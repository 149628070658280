import { relayerApi } from "../config/config";
import { Collection, ApiResponse } from "../config/types";

export const postCollection = async (collection: Collection): Promise<ApiResponse<Collection>> => {
  const rawResponse = await relayerApi.post(`collection`, collection);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.collection,
  };
};

export const validateCollection = async (
  name?: string,
  symbol?: string,
  shortUrl?: string
): Promise<ApiResponse<undefined>> => {
  const rawResponse = await relayerApi.get(
    `collection/valid-collection?name=${name}&symbol=${symbol}&shortUrl=${shortUrl}`
  );
  const response = rawResponse.data;

  return {
    message: response.message,
    status: response.available,
  };
};

export const getCollectionByShortUrl = async (shortUrl: string): Promise<ApiResponse<Collection>> => {
  const rawResponse = await relayerApi.get(`collection/by-shorturl/${shortUrl}`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.collection,
  };
};

export async function getCollectionbyCollectionAddress<T = Collection>(address: string): Promise<ApiResponse<T>> {
  const rawResponse = await relayerApi.get(`collection/${address}`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data,
  };
}

export async function getAllCollections<T = Collection>(): Promise<ApiResponse<T[]>> {
  const rawResponse = await relayerApi.get("collection");
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.collections,
  };
}

export const getAllCollectionsByUserAddress = async (userAddress: string): Promise<ApiResponse<Collection[]>> => {
  const rawResponse = await relayerApi.get(`collection/user/${userAddress}`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.userCollections,
  };
};

export const checkShortUrlAvailability = async (shortUrl: string): Promise<boolean> => {
  const response = await relayerApi.get(`collection/shortUrl?url=${shortUrl}`);
  return response.data;
};
