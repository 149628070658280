import { Button, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import mintbottom from "src/assets/images/mintbottom.png";
import logo from "src/assets/images/mintingdapplogo.gif";
import minttop from "src/assets/images/minttop.png";
import { useNFTContract } from "src/hooks/useNFTContract";
import Confetti from "react-confetti";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    marginLeft: "270px",
    backgroundImage: `url(${minttop}), url(${mintbottom})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "90% 200px, 90% 200px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "top center, bottom center",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "100% 200px, 100% 200px",
      marginLeft: 0,
    },
  },
  inputField: {
    marginTop: 50,
    backgroundColor: "rgba(124, 105, 227, 0.3)",
    height: "50px",
  },
  input: {
    textAlign: "center",
    color: "white",
    fontSize: "1.3rem",
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  addSubtract: {
    padding: 0,
    height: "50px",
    borderRadius: "0",
    fontSize: "1.4rem",
  },
}));

const Minting = () => {
  const classes = useStyles();
  const [amount, setAmount] = React.useState(1);
  const { mintNFT, loading, isMinted, displayPrice } = useNFTContract();

  function handleAmount(e: any) {
    //
    if (+e.target.value >= 5) {
      setAmount(5);
      return;
    }

    if (+e.target.value <= 1) {
      setAmount(1);
      return;
    }

    setAmount(+e.target.value);
  }

  function handleAdd() {
    setAmount((prevState) => {
      if (prevState >= 5) {
        return 5;
      }
      return prevState + 1;
    });
  }
  function handleSubtract() {
    setAmount((prevState) => {
      if (prevState <= 1) {
        return 1;
      }
      return prevState - 1;
    });
  }

  return (
    <div className={classes.root}>
      {isMinted && <Confetti />}
      <img src={logo} height={"300px"} style={{ marginTop: "100px", pointerEvents: "none" }} />
      <Box sx={{ display: "flex", alignItems: "flex-end", gap: 1, px: 2 }}>
        <Button variant="contained" className={classes.addSubtract} onClick={handleSubtract}>
          -
        </Button>
        <TextField
          InputProps={{
            className: classes.inputField,
          }}
          inputProps={{
            className: classes.input,
          }}
          type={"number"}
          variant="standard"
          value={amount}
          onChange={handleAmount}
        />
        <Button variant="contained" className={classes.addSubtract} onClick={handleAdd}>
          +
        </Button>
      </Box>
      <Typography fontWeight={"bold"} color={"black"}>
        {displayPrice} BNB
      </Typography>

      <div
        style={{
          background: "linear-gradient(180deg, #5E4BC5 0%, #7C69E3 100%)",
          padding: "4px",
          borderRadius: "8px",
          marginTop: 10,
        }}
      >
        <Button
          disabled={loading}
          variant="contained"
          sx={{ pl: 4, pr: 4, background: "linear-gradient(180deg, #7C69E3 0%, #46398E 100%)", border: "8px" }}
          onClick={() => mintNFT(amount)}
        >
          {loading ? "Minting..." : "Mint"}
        </Button>
      </div>
    </div>
  );
};

export default Minting;
