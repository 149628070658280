import { UtilsConfig } from "../config/types";
import { ConfigContextProvider } from "../contexts/configContext";
import { RefreshContextProvider } from "../contexts/refreshContext";

const UtilsProvider = ({ children, config }: { children: JSX.Element, config: UtilsConfig }) => {

  return (
    <ConfigContextProvider config={config}>
      <RefreshContextProvider>
        {children}
      </RefreshContextProvider>
    </ConfigContextProvider>
  )

}

export default UtilsProvider;
