import { relayerApi } from '../config/config'
import { ApiResponse } from '../config/types'

export const getCategories = async (): Promise<ApiResponse<string[]>> => {
    const rawResponse = await relayerApi.get(`settings/categories`)
    const response = rawResponse.data
    return {
        message: "",
        status: response.status,
        data: response.data.categories,
    }
}
