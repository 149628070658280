import React, { useContext } from "react";
import defaultConfig from "../config/config";
import { MarketplaceSdkConfig, CURRENT_TASK, TASKS_TYPE } from "../config/types";

const NftVillageSdkContext = React.createContext<{
  config: MarketplaceSdkConfig;
  setConfig: (config: MarketplaceSdkConfig) => void;
  Task: TASKS_TYPE;
  currentTasks: Set<string>;
}>({
  config: defaultConfig,
  setConfig: (config: MarketplaceSdkConfig) => {},
  Task: {} as TASKS_TYPE,
  currentTasks: new Set<string>(),
});

export const useConfig = () => {
  return useContext(NftVillageSdkContext);
};

export default NftVillageSdkContext;
