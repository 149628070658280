import { useState, useEffect } from "react";
import { Multicall } from "ethereum-multicall";
import { useConfig } from "../contexts/configContext";
import { useEthers } from './useEthers'
import lp_abi from "../assets/abi/lpAbi.json";
import erc20_abi from "../assets/abi/erc20Abi.json";
import BigNumber from "bignumber.js";
import { LPToken } from '../config/types'
import { toLowerUnit } from "../utils";
import { ethers as ethersLibrary } from "ethers";
/**
 * 
 * A easy to use hook, to get token info and price of tokens of an LP token
 * 
//  * @param {Address Of LP Token} address 
//  * @param {Symbol of token to use as base token to calculate price} baseTokenSymbol 
//  * @param {Address of token to use as base token to calculate price} baseTokenAddress 
 * @returns 
 * 
 * If baseTokenSymbol is provided, it will be given priority over baseTokenAddress
 * 
 */
export const useLp = (address: string, baseTokenIdentifier: string) => {
    const { ethers } = useEthers();
    const [loading, setLoading] = useState(false);
    const [lp, setLp] = useState<LPToken>();


    useEffect(() => {
        console.log('ethers', ethers)
        const fetchData = async () => {
            if (!ethers) return;

            setLoading(true);
            try {
                const multicall = new Multicall({ ethersProvider: ethers, tryAggregate: true });
                const lpCall = [
                    {
                        reference: "lp",
                        contractAddress: address,
                        abi: lp_abi,
                        calls: [
                            {
                                reference: "token0",
                                methodName: "token0",
                                methodParameters: [],
                            },
                            {
                                reference: "token1",
                                methodName: "token1",
                                methodParameters: [],
                            },
                            {
                                reference: "name",
                                methodName: "name",
                                methodParameters: [],
                            },
                            {
                                reference: "symbol",
                                methodName: "symbol",
                                methodParameters: [],
                            },
                            {
                                reference: "decimals",
                                methodName: "decimals",
                                methodParameters: [],
                            },
                            {
                                reference: "totalSupply",
                                methodName: "totalSupply",
                                methodParameters: [],
                            },
                        ],
                    },
                ];
                const data1 = await multicall.call(lpCall);
                const lpresult = data1.results.lp.callsReturnContext;

                const tokensCall = [
                    {
                        reference: "token0",
                        contractAddress: lpresult[0].returnValues[0],
                        abi: erc20_abi,
                        calls: [
                            {
                                reference: "name",
                                methodName: "name",
                                methodParameters: [],
                            },
                            {
                                reference: "symbol",
                                methodName: "symbol",
                                methodParameters: [],
                            },
                            {
                                reference: "decimals",
                                methodName: "decimals",
                                methodParameters: [],
                            },
                            {
                                reference: "totalSupply",
                                methodName: "totalSupply",
                                methodParameters: [],
                            },
                            {
                                reference: "balanceOf",
                                methodName: "balanceOf",
                                methodParameters: [address],
                            },
                            // {
                            //     reference: "balanceOf",
                            //     methodName: "balanceOf",
                            //     methodParameters: [account],
                            // },
                        ],
                    },
                    {
                        reference: "token1",
                        contractAddress: lpresult[1].returnValues[0],
                        abi: erc20_abi,
                        calls: [
                            {
                                reference: "name",
                                methodName: "name",
                                methodParameters: [],
                            },
                            {
                                reference: "symbol",
                                methodName: "symbol",
                                methodParameters: [],
                            },
                            {
                                reference: "decimals",
                                methodName: "decimals",
                                methodParameters: [],
                            },
                            {
                                reference: "totalSupply",
                                methodName: "totalSupply",
                                methodParameters: [],
                            },
                            {
                                reference: "balanceOf",
                                methodName: "balanceOf",
                                methodParameters: [address],
                            },
                            // {
                            //     reference: "balanceOf",
                            //     methodName: "balanceOf",
                            //     methodParameters: [account],
                            // },
                        ],
                    },
                ];

                const data2 = await multicall.call(tokensCall);
                const token0result = data2.results.token0.callsReturnContext;
                const token1result = data2.results.token1.callsReturnContext;

                const token0 = {
                    address: lpresult[0].returnValues[0],
                    name: token0result[0].returnValues[0],
                    symbol: token0result[1].returnValues[0],
                    decimals: token0result[2].returnValues[0],
                    totalSupply: token0result[3].returnValues[0],
                    // balance: new BigNumber(token0result[5].returnValues[0].hex, 16),
                }

                const token1 = {
                    address: lpresult[1].returnValues[0],
                    name: token1result[0].returnValues[0],
                    symbol: token1result[1].returnValues[0],
                    decimals: token1result[2].returnValues[0],
                    totalSupply: token1result[3].returnValues[0],
                    // balance: new BigNumber(token0result[5].returnValues[0].hex, 16),
                }

                let baseToken;
                let qouteToken;
                if (ethersLibrary.utils.isAddress(baseTokenIdentifier)) {
                    baseToken = token0.address === baseTokenIdentifier ? token0 : token1;
                    qouteToken = token0.address === baseTokenIdentifier ? token1 : token0;
                } else {
                    baseToken = token0.symbol === baseTokenIdentifier ? token0 : token1;
                    qouteToken = token0.symbol === baseTokenIdentifier ? token1 : token0;
                }

                const token0Balance = new BigNumber(token0result[4].returnValues[0].hex, 16);
                const token1Balance = new BigNumber(token1result[4].returnValues[0].hex, 16)

                const qouteTokenBalance = token0.address === baseToken.address ? token1Balance : token0Balance;
                const baseTokenBalance = token0.address === baseToken.address ? token0Balance : token1Balance;
                const qouteTokenUnitBalance = toLowerUnit(qouteTokenBalance.toString(), qouteToken.decimals)
                const baseTokenUnitBalance = toLowerUnit(baseTokenBalance.toString(), baseToken.decimals);

                setLp({
                    address: address,
                    token0: lpresult[0].returnValues[0],
                    token1: lpresult[1].returnValues[0],
                    name: lpresult[2].returnValues[0],
                    symbol: lpresult[3].returnValues[0],
                    decimals: lpresult[4].returnValues[0],
                    totalSupply: new BigNumber(lpresult[5].returnValues[0].hex, 16),
                    qouteToken: qouteToken,
                    baseToken: baseToken,
                    qouteTokenBalance: qouteTokenBalance,
                    baseTokenBalance: baseTokenBalance,
                    price: {
                        baseTokenPrice: baseTokenUnitBalance.div(qouteTokenUnitBalance),
                        qouteTokenPrice: qouteTokenUnitBalance.div(baseTokenUnitBalance),
                    }
                });

            }
            catch (e) {
                console.log(`Error During Multicall: ${e}`)
            }
            setLoading(false);
        };
        fetchData();
    }, [ethers]);

    return { lp, loading };
};

/**
 * Use this hook with token-native lp to get usd price
 * 
//  * @param {Address of TOKEN-NATIVE lp pair} address 
 * @returns 
 */
export const useUSDLp = (address: string): { lp: LPToken | undefined, loading: boolean, baseTokenUsdPrice: BigNumber | undefined } => {
    const { config } = useConfig();
    const { nativeUsdLp, wrappedNative } = config

    if (!nativeUsdLp)
        throw new Error(`@react-dapp/utils error: NATIVE-USD LP address is not provided in config`);
    if (!wrappedNative)
        throw new Error(`@react-dapp/utils error: WrappedNative address is not provided in config`);

    const { lp: usdLP } = useLp(nativeUsdLp.address, wrappedNative?.address);
    const { lp: tokenLP, loading } = useLp(address, wrappedNative.address);
    const [baseTokenUsdPrice, setBaseTokenUsdPrice] = useState<BigNumber>()

    useEffect(() => {
        if (usdLP && tokenLP) {
            setBaseTokenUsdPrice(tokenLP.price.baseTokenPrice.div(usdLP.price.baseTokenPrice));
        }
    }, [usdLP, tokenLP]);

    return {
        lp: tokenLP,
        loading: loading,
        baseTokenUsdPrice
    };
};