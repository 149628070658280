import { useEthers } from "./useEthers";
import { useState } from "react";
import { STATE } from "../config/types";

export const useWaleltSign = () => {
  const [signature, setSignature] = useState<string | null>(null);
  const [signState, setSignState] = useState(STATE.IDLE);
  const { signer } = useEthers();

  const sign = async (hash: string) => {
    if (signer) {
      try {
        // const value = ethers.utils.arrayify(hash);
        setSignState(STATE.BUSY);
        const sig = await signer.signMessage(hash);
        setSignature(sig);
        setSignState(STATE.SUCCEED);
        return sig;
      } catch (e) {
        console.log(e);
        setSignState(STATE.FAILED);
      }
    }
  };

  return { sign, signState, signature };
};
