import React from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Typography, Theme, Popover, List, ListItem, ListItemText, Button } from "@mui/material";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { useWallet } from "@react-dapp/wallet";
import { useEthers } from "@react-dapp/utils";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  iconBTn: {
    marginRight: 5,
  },
  accountAdd: {
    fontSize: 12,
  },
}));

interface Props {}

const WalletIcon: React.FC<Props> = () => {
  const classes = useStyles();
  const { account, deactivate, setOpen } = useWallet();
  const { displayAccount } = useEthers();
  const [pop, setPop] = React.useState<HTMLButtonElement | null>(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined = (event) => {
    !account ? setOpen(true) : setPop(event.currentTarget);
  };

  const logout = () => {
    deactivate();
    localStorage.removeItem("token");
    localStorage.removeItem("Allow-Wallet-Reconnect");
    setPop(null);
  };

  return (
    <span className="center" style={{ marginLeft: "auto" }}>
      <div
        style={{ background: "linear-gradient(180deg, #5E4BC5 0%, #7C69E3 100%)", padding: "4px", borderRadius: "8px" }}
      >
        <Button
          variant="contained"
          sx={{ pl: 4, pr: 4, background: "linear-gradient(180deg, #7C69E3 0%, #46398E 100%)", border: "8px" }}
          onClick={handleClick}
        >
          {!account ? "Connect Wallet" : displayAccount}
        </Button>
      </div>
      <Popover
        open={!!pop}
        anchorEl={pop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => setPop(null)}
      >
        <List>
          <ListItem button onClick={logout}>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Popover>
    </span>
  );
};

export default WalletIcon;
