import React, { useCallback, useRef, useState } from "react";
import defaultConfig from "../config/config";
import { CURRENT_TASK, MarketplaceSdkConfig, TASKS_TYPE } from "../config/types";
import NftVillageSdkContext from "../contexts/nftVillageSdkContext";

const NftVillageSdkProvider: React.FC<{ children: React.ReactNode; config: MarketplaceSdkConfig }> = ({
  children,
  config = defaultConfig,
}) => {
  const [_config, _setConfig] = useState({
    ...config,
  });
  const [_currentTask, _setCurrentTask] = useState<Set<string>>(new Set<string>());
  const taskRef = useRef(new Set<string>());

  const Task: TASKS_TYPE = fn();

  function fn() {
    let taskFns: TASKS_TYPE = {} as TASKS_TYPE;

    const task = Object.keys(CURRENT_TASK);
    for (let i = 0; i < task.length; i++) {
      const item = task[i];
      const start = () => {
        _addCurrentTask(item);
      };
      const stop = () => {
        _removeCurrentTask(item);
      };
      const isRunning = () => {
        return _currentTask.has(item);
      };
      taskFns[item as any] = { start, stop, isRunning };
    }
    return taskFns as {
      [key in keyof typeof CURRENT_TASK]: { start: () => void; stop: () => void; isRunning: () => boolean };
    };
  }

  React.useEffect(() => {
    console.log("ref updated", taskRef.current);
    _setCurrentTask(new Set(taskRef.current));
  }, [taskRef.current.size]);

  function _addCurrentTask(task: string) {
    taskRef.current.add(task);
    _setCurrentTask(taskRef.current);
  }

  function _removeCurrentTask(task: string) {
    taskRef.current.delete(task);
    _setCurrentTask(taskRef.current);
  }

  return (
    <NftVillageSdkContext.Provider
      value={{
        config: _config,
        setConfig: _setConfig,
        Task,
        currentTasks: _currentTask,
      }}
    >
      {children}
    </NftVillageSdkContext.Provider>
  );
};

export { NftVillageSdkContext, NftVillageSdkProvider };
