import BigNumber from 'bignumber.js/bignumber'
import { UtilsConfig } from './types'

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
})

export const ERC721_INTERFACE = '0x80ac58cd';
export const ERC1155_INTERFACE = '0xd9b67a26';

export const BSC_DEFAULT_CONFIG: UtilsConfig = {
    provider: undefined,
    // rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    rpcUrl: 'https://bsc-dataseed.binance.org/',
    wrappedNative: {
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        symbol: 'WBNB',
        name: 'WBNB',
        decimals: 18
    },
    usd: {
        address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        name: 'BUSD',
        symbol: 'BUSD',
        decimals: 18

    },
    nativeUsdLp: {
        name: 'LP',
        address: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
        symbol: 'LP',
        decimals: 18
    }
}
