import { relayerApi } from "../config/config";
import {
  Order,
  SaleKind,
  ApiResponse,
  OrderAsset,
  StatusType,
  SpecificOrderData,
  AllOrders,
  HistoryOfOrder,
} from "../config/types";

export const postOrder = async (order: Order): Promise<ApiResponse<Order>> => {
  const rawResponse = await relayerApi.post("orders", order);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.order,
  };
};
export const getOrderERC721 = async (orderData: OrderAsset): Promise<ApiResponse<Order>> => {
  const rawResponse = await relayerApi.get(`orders/${orderData.asset}/${orderData.assetId}`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.order,
  };
};
// TODO: add type for specificOrderData api

export const getOrderERC1155 = async (orderData: OrderAsset): Promise<ApiResponse<Order[]>> => {
  const rawResponse = await relayerApi.get(`orders/${orderData.asset}/${orderData.assetId}?type=1`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.order,
  };
};

export const postBid = async (order: Order): Promise<ApiResponse<Order>> => {
  const rawResponse = await relayerApi.post(`orders/bid`, order);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.order,
  };
};
export const deleteOrder = async (orderData: OrderAsset) => {
  const rawResponse = await relayerApi.delete(`orders/${orderData.asset}/${orderData.assetId}`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
  };
};
export const getOrderHistory = async (orderData: OrderAsset): Promise<ApiResponse<HistoryOfOrder[]>> => {
  const rawResponse = await relayerApi.get(`orders/orderhistory/${orderData.asset}/${orderData.assetId}`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.orderHistory,
  };
};
export const getOrdersByCollection = async (address: string, saleKind: SaleKind): Promise<ApiResponse<Order[]>> => {
  const rawResponse = await relayerApi.get(`orders/collection-orders/${address}?saleKind=${saleKind}`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.orders,
  };
};

export const getAllListedOrders = async (
  saleKind: SaleKind,
  page: number,
  limit: number,
  status: StatusType
): Promise<ApiResponse<AllOrders>> => {
  const rawResponse = await relayerApi.get(`orders?salekind=${saleKind}&status=${status}&page=${page}&limit=${limit}`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.orders,
  };
};
export const getAllListedOrdersForAddress = async (userAddress: string): Promise<ApiResponse<Order[]>> => {
  const rawResponse = await relayerApi.get(`orders/user-orders/${userAddress}`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.orders,
  };
};

export const getSpecificOrders = async (specificOrderData: SpecificOrderData): Promise<ApiResponse<Order[]>> => {
  const rawResponse = await relayerApi.post(`orders/specific-orders`, specificOrderData);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    data: response.data.orders,
  };
};

export const hitListener = async () => {
  const rawResponse = await relayerApi.get(`check-listener`);
  const response = rawResponse.data;
  return {
    message: response.message,
    status: response.status,
    value: response.value,
  };
};
